const ci_score_buttons = [{ name: 'c', display: 'C' }, { name: 'i', display: 'I' }];
const cip_score_buttons = [{ name: 'c', display: 'C' }, { name: 'i', display: 'I' }, { name: 'p', display: 'P' }];
const prompt_button = [{ name: 'p', display: 'P' }];
const be_prompt_buttons = [{ name: 'pb', display: 'P' }, { name: 'pe', display: 'P' }];

const descriptive_prompt_buttons = [
    { name: 'fp', display: 'FP', long_display: 'Full Physical', icon: 'icon-hand', class: 'fp' },
    { name: 'pp', display: 'PP', long_display: 'Partial Physical', icon: 'icon-hand', class: 'pp' },
    { name: 'fv', display: 'FV', long_display: 'Full Vocal', icon: 'icon-mouth', class: 'fv' },
    { name: 'pv', display: 'PV', long_display: 'Partial Vocal', icon: 'icon-mouth', class: 'pv' },
    { name: 'fvi', display: 'FVi', long_display: 'Full Visual', icon: 'icon-eye', class: 'fvi' },
    { name: 'pvi', display: 'PVi', long_display: 'Partial Visual', icon: 'icon-eye', class: 'pvi' },
    { name: 'fm', display: 'FM', long_display: 'Full Model', icon: 'icon-man', class: 'fm' },
    { name: 'pm', display: 'PM', long_display: 'Partial Model', icon: 'icon-man', class: 'pm' },
    { name: 'fg', display: 'FG', long_display: 'Full Gesture', icon: 'icon-finger', class: 'fg' },
    { name: 'pg', display: 'PG', long_display: 'Partial Gesture', icon: 'icon-finger', class: 'pg' },
    { name: 'fpo', display: 'FPo', long_display: 'Full Position', icon: 'icon-card', class: 'fpo' },
    { name: 'ppo', display: 'PPo', long_display: 'Partial Position', icon: 'icon-card', class: 'ppo' }
];

const manding_descriptive_prompt_buttons = [
    { name: 'fp', display: 'FP', long_display: 'Full Physical', icon: 'icon-hand', class: 'fp' },
    { name: 'pp', display: 'PP', long_display: 'Partial Physical', icon: 'icon-hand', class: 'pp' },
    { name: 'fv', display: 'FV', long_display: 'Full Vocal', icon: 'icon-mouth', class: 'fv' },
    { name: 'pv', display: 'PV', long_display: 'Partial Vocal', icon: 'icon-mouth', class: 'pv' },
    { name: 'fvi', display: 'FVi', long_display: 'Full Visual', icon: 'icon-eye', class: 'fvi' },
    { name: 'pvi', display: 'PVi', long_display: 'Partial Visual', icon: 'icon-eye', class: 'pvi' },
    { name: 'fm', display: 'FM', long_display: 'Full Model', icon: 'icon-man', class: 'fm' },
    { name: 'pm', display: 'PM', long_display: 'Partial Model', icon: 'icon-man', class: 'pm' },
    { name: 'fg', display: 'FG', long_display: 'Full Gesture', icon: 'icon-finger', class: 'fg' },
    { name: 'pg', display: 'PG', long_display: 'Partial Gesture', icon: 'icon-finger', class: 'pg' },
    { name: 'fpo', display: 'FPo', long_display: 'Full Position', icon: 'icon-card', class: 'fpo' },
    { name: 'ppo', display: 'PPo', long_display: 'Partial Position', icon: 'icon-card', class: 'ppo' },
    { name: 'lqs', display: 'LQS', long_display: 'Leading Question or Statement', icon: 'icon-confusion', class: 'lqs' }
];

const first_trial_descriptive_prompt_buttons = [
    { name: 'fp', display: 'FP', long_display: 'Full Physical', icon: 'icon-hand', class: 'fp' },
    { name: 'pp', display: 'PP', long_display: 'Partial Physical', icon: 'icon-hand', class: 'pp' },
    { name: 'fv', display: 'FV', long_display: 'Full Vocal', icon: 'icon-mouth', class: 'fv' },
    { name: 'pv', display: 'PV', long_display: 'Partial Vocal', icon: 'icon-mouth', class: 'pv' },
    { name: 'fvi', display: 'FVi', long_display: 'Full Visual', icon: 'icon-eye', class: 'fvi' },
    { name: 'pvi', display: 'PVi', long_display: 'Partial Visual', icon: 'icon-eye', class: 'pvi' },
    { name: 'fm', display: 'FM', long_display: 'Full Model', icon: 'icon-man', class: 'fm' },
    { name: 'pm', display: 'PM', long_display: 'Partial Model', icon: 'icon-man', class: 'pm' },
    { name: 'fg', display: 'FG', long_display: 'Full Gesture', icon: 'icon-finger', class: 'fg' },
    { name: 'pg', display: 'PG', long_display: 'Partial Gesture', icon: 'icon-finger', class: 'pg' },
    { name: 'fpo', display: 'FPo', long_display: 'Full Position', icon: 'icon-card', class: 'fpo' },
    { name: 'ppo', display: 'PPo', long_display: 'Partial Position', icon: 'icon-card', class: 'ppo' },
    { name: 'nop', display: 'NP', long_display: 'No Prompt', icon: '', class: 'nop' }
];

const reward_buttons = [
    { name: 'reward_attn_praise', display: 'Attention – Praise' },
    { name: 'reward_attn_play_game', display: 'Attention - Play/Game' },
    { name: 'reward_tangible_toy', display: 'Tangible - Toy' },
    { name: 'reward_tangible_token', display: 'Tangible - Token' },
    { name: 'reward_tangible_edible', display: 'Tangible – Edible' },
    { name: 'reward_sensory_perseverative_item_activity', display: 'Sensory – Perseverative Item/Activity' },
    { name: 'reward_escape_non_preferred_task_activity', display: 'Escape – Non-Preferred Task/Activity' }
];

const incorrect_buttons = [
    { name: 'incorrect_non_response', display: 'Non-response' },
    { name: 'incorrect_other_target', display: 'Other Target' },
    { name: 'incorrect_problem_behavior', display: 'Problem Behavior' }
];

const disc_steps = (isMaintenance) => {
    if (!isMaintenance) {
        return [
            { name: '', display: '' },
            { name: 'MT T 1 in iso', short_display: 'MT T 1 in iso', display: 'MT Target 1 in iso', target: 1 },
            { name: 'MT T 1 vs unkn d', short_display: 'MT T 1 vs unkn d', display: 'MT Target 1 vs unknown distractors', target: 1 },
            { name: 'MT T 2 in iso', short_display: 'MT T 2 in iso', display: 'MT Target 2 in iso', target: 2 },
            { name: 'MT T 2 vs unkn d', short_display: 'MT T 2 vs unkn d', display: 'MT Target 2 vs unknown distractors', target: 2 },
            { name: 'MT T 1 vs T 2', short_display: 'MT T 1 vs T 2', display: 'MT Target 1 vs Target 2', target: 1 },
            { name: 'MT T 2 vs T 1', short_display: 'MT T 2 vs T 1', display: 'MT Target 2 vs Target 1', target: 2 },
            { name: 'RR T 1 and T 2', short_display: 'RR T 1 and T 2', display: 'RR Target 1 and Target 2', target: 'both' },
            { name: 'MT T (3+) in iso', short_display: 'MT T (3+) in iso', display: 'MT Target (3+) in iso', target: 3 },
            { name: 'MT T (3+) vs unkn d', short_display: 'MT T 3(+) vs unkn d', display: 'MT Target (3+) vs unknown distractors', target: 3 },
            { name: 'MT T (3+) vs kn d', short_display: 'MT T (3+) vs kn d', display: 'MT Target (3+) vs known distractors', target: 3 },
            { name: 'RR T (3+) and mast d', short_display: 'RR T (3+) and mast d', display: 'RR Target (3+) and mastered distractors', target: 3 }
        ];
    }
    
    return [
        { name: '', display: '' },
        { name: 'RR T 1 and T 2', short_display: 'RR T 1 and T 2', display: 'RR Target 1 and Target 2', target: 'both' },
        { name: 'RR T (3+) and mast d', short_display: 'RR T (3+) and mast d', display: 'RR Target (3+) and mastered distractors', target: 3 }
    ]
};


const lookup = (isMaintenance) => {
    return disc_steps(isMaintenance).reduce((acc, step) => {
        if (step.name === '') return acc;
        acc[step.name] = step;
        return acc;
    }, {});
}

export {
    ci_score_buttons,
    cip_score_buttons,
    prompt_button,
    be_prompt_buttons,
    descriptive_prompt_buttons,
    manding_descriptive_prompt_buttons,
    first_trial_descriptive_prompt_buttons,
    reward_buttons,
    incorrect_buttons,
    disc_steps,
    lookup,
};
