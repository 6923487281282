import _ from 'lodash';
import Moment from 'moment';
import { REDUCTION_TYPES } from '../../common/constants/data-sheets';

const hasProperty = (property) => {
    return property && property === true;
};

const updateScoreAndSelection = (oldObj, newObj) => {
    oldObj.quantity += newObj.quantity;
    oldObj.score.push(newObj.score);
}

export const scoreLabel = {
    C: 'C',
    I: 'I',
    FP: 'FP',
    PP: 'PP',
    FV: 'FV',
    PV: 'PV',
    FVI: 'FVi',
    FT: 'FT',
    PVI: 'PVi',
    PT: 'PT',
    P: 'P',
    FM: 'FM',
    PM: 'PM',
    FG: 'FG',
    PG: 'PG',
    FPO: 'FPo',
    PPO: 'PPo',
    TD: 'TD',
    LPLR: 'LPLR',
    LQS: 'LQS',
    NOP: 'NoP',
};

const scoreAndSelectionLookup = {
    [scoreLabel.C]: { quantity: 1, score: scoreLabel.C },
    [scoreLabel.I]: { quantity: 0, score: scoreLabel.I },
    [scoreLabel.FP]: { quantity: .1, score: scoreLabel.FP },
    [scoreLabel.PP]: { quantity: .2, score: scoreLabel.PP },
    [scoreLabel.FV]: { quantity: .2, score: scoreLabel.FV },
    [scoreLabel.PV]: { quantity: .3, score: scoreLabel.PV },
    [scoreLabel.FVI]: { quantity: .3, score: scoreLabel.FVI },
    [scoreLabel.FT]: { quantity: .3, score: scoreLabel.FT },
    [scoreLabel.PVI]: { quantity: .4, score: scoreLabel.PVI },
    [scoreLabel.PT]: { quantity: .4, score: scoreLabel.PT },
    [scoreLabel.P]: { quantity: .5, score: scoreLabel.P },
    [scoreLabel.FM]: { quantity: .5, score: scoreLabel.FM },
    [scoreLabel.PM]: { quantity: .6, score: scoreLabel.PM },
    [scoreLabel.FG]: { quantity: .7, score: scoreLabel.FG },
    [scoreLabel.PG]: { quantity: .8, score: scoreLabel.PG },
    [scoreLabel.FPO]: { quantity: .8, score: scoreLabel.FPO },
    [scoreLabel.PPO]: { quantity: .9, score: scoreLabel.PPO },
    [scoreLabel.TD]: { quantity: .9, score: scoreLabel.TD },
    [scoreLabel.LPLR]: { quantity: .9, score: scoreLabel.LPLR },
};

const numberHasDecimal = (n) => {
    return n % 1 !== 0;
}

export const getTrialScoreAndSelection = (trial) => {
    const obj = { quantity: 0, score: [] };
    const ftcPBegin = trial.ftc_p_begin;
    const ftcPEnd = trial.ftc_p_end;

    // only this case we can return right away
    if (hasProperty(trial.c)) return scoreAndSelectionLookup[scoreLabel.C];
    
    if (hasProperty(trial.i)) updateScoreAndSelection(obj, scoreAndSelectionLookup[scoreLabel.I]); // only need to check once

    // Run this once for all score
    Object.keys(scoreLabel).forEach(k => {
        if (scoreLabel.I === k) return; // skip this as we already done it outside
        const selectionKey = scoreLabel[k];
        if (hasProperty(trial[k.toLowerCase()])) updateScoreAndSelection(obj, scoreAndSelectionLookup[selectionKey]);
    });

    // WE NO LONGER NEED TO SCORE BEGIN AND END PROMPT
    // Run this if there is begin and end prompt
    // The quantity for begin and end need to divide by 2
    // [ftcPBegin, ftcPEnd].forEach(k => {
    //     const selectionKey = scoreLabel[k.toUpperCase()];
    //     if (scoreAndSelectionLookup[selectionKey]) {
            
    //         const scoreAndSelectionObj = _.cloneDeep(scoreAndSelectionLookup[selectionKey]);
    //         scoreAndSelectionObj.quantity /= 2;
    //         updateScoreAndSelection(obj, scoreAndSelectionObj);
    //     }        
    // });
    
    return { quantity: numberHasDecimal(obj.quantity) ? obj.quantity.toFixed(2) : obj.quantity, score: obj.score.join(" - ")};
};

const getBaselineValue = (pbxDsType = {}) => {
    let baselineValue = null;
    switch (pbxDsType.reduction_type) {
        case REDUCTION_TYPES.PERCENT_OF_INTERVALS:
        case REDUCTION_TYPES.PERCENT_OF_TOTAL_DURATION: {
            baselineValue = pbxDsType.reduction_percent_start;
            break;
        }
        case REDUCTION_TYPES.RATE:
        case REDUCTION_TYPES.AVERAGE_DURATION: {
            baselineValue = pbxDsType.reduction_start;
            break;
        }
        case REDUCTION_TYPES.FREQUENCY: {
            baselineValue = pbxDsType.reduction_frequency_start;
            break;
        }
        default:
            break;
    }
    return baselineValue;
};

export const addPBxBaselineDataPoint = ({
    labels,
    data,
    pbxDsType,
    reductionType,
    dateOption,
    showSignificantEvents,
    addEventPhases,
    significantEvents,
    phases,
}) => {
    const startDate = Moment(dateOption.startDate);
    const endDate = Moment(dateOption.endDate);
    if (pbxDsType && pbxDsType.reduction_type === reductionType) {
        const baselineDate = Moment(pbxDsType.createdAt);
        const shouldInjectBaselineData =
            (startDate.isValid() && baselineDate.isSameOrAfter(startDate)) &&
            (endDate.isValid() && baselineDate.isSameOrBefore(endDate));
        if (shouldInjectBaselineData) {
            const baselineValue = getBaselineValue(pbxDsType);
            if (baselineValue !== null) {
                showSignificantEvents && addEventPhases(significantEvents, baselineDate.clone().endOf('day'), phases, labels, data);
                labels.push(['', baselineDate.format('M/D'), 'Baseline']);
                data.push(baselineValue);
            }
        }
    }
};

export const addRBxBaselineDataPoint = ({
    labels,
    data,
    replacementBehavior,
    reductionType,
    dateOption,
    showSignificantEvents,
    addEventPhases,
    significantEvents,
    phases,
}) => {
    const startDate = Moment(dateOption.startDate);
    const endDate = Moment(dateOption.endDate);
    if (replacementBehavior && replacementBehavior.measurement_type === reductionType) {
        const baselineDate = Moment(replacementBehavior.createdAt);
        const shouldInjectBaselineData =
            (startDate.isValid() && baselineDate.isSameOrAfter(startDate)) &&
            (endDate.isValid() && baselineDate.isSameOrBefore(endDate));
        if (shouldInjectBaselineData) {
            const baselineValue = replacementBehavior.goal_start;
            showSignificantEvents && addEventPhases(significantEvents, baselineDate.clone().endOf('day'), phases, labels, data);
            labels.push(['', baselineDate.format('M/D'), 'Baseline']);
            data.push(baselineValue);
        }
    }
};
