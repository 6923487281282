const chartTypeLabel = {
    LINE: "Line",
    BAR: "Bar"
}

const chartDataLabel = {
    ANTECEDENT: "Antecedent",    
    ANTECEDENTS: "Antecedents",
    AVERAGE_DURATION_TIME: "Average Duration Time",
    AVERAGE_DURATION_DISPLAY: "Average Duration (hh:mm:ss)",
    AVERAGE_LAPSED: "Average Interresponse Time",   // used to be called Average Lapsed
    AVERAGE_LAPSED_DISPLAY: "Average Interresponse Time (hh:mm:ss)", // used to be called Average Lapsed
    CONSEQUENCE: "Consequence",
    CONSEQUENCES: "Consequences",
    DATE: "Date",
    INITIALS: "Initials",
    NUMBER_OF_ANTECEDENTS: "# of Antecedents",
    NUMBER_OF_CONSEQUENCES: "# of Consequences",
    NUMBER_OF_PROBLEM_BEHAVIOR: "# of Problem Behavior",
    PERCENTAGE: "Pct",
    PERCENT_OF_OPPORTUNITIES: "Percent of Opportunities",    
    PERCENT_DURATION: "Percent Duration",
    PROBABILITY: "Probablity",
    RATE: "Rate",
    SESSION: "Session",
    TOTAL_OCCURRED: "Tot. Occurred",
    TOTAL_DURATION_TIME: "Tot. Duration Time",
    TOTAL_DURATION_DISPLAY: "Tot. Duration (hh:mm:ss)",
    TOTAL_ESPISODES: "Tot. Episodes",    
    TOTAL_INTERVALS: "Tot. Intervals",
    TOTAL_OBSERVATION_TIME: "Tot. Observation Time",
    TOTAL_OBSERVATION_DISPLAY: "Total Obs Period (hh:mm:ss)",

    TOTAL_LAPSED: "Tot. Lapsed",
    TOTAL_TIME_LAPSED_DISPLAY: "Tot. Time Lapsed (hh:mm:ss)",

    TOTAL_TRIALS: "Tot. Trials",
    TOTAL_YES: "Tot. Yes",
    TOTAL_MASTERED_TRIALS: "Tot. Mastered Trials",
    TOTAL_MASTERED_TARGETS: "Tot. Mastered Targets",
    TOTAL_MASTERED_TARGETS_PER_DAY: "Mastered Targets Per Day",
    TOTAL_CORRECT_TRIALS: "Tot. Correct Trials",
    TOTAL_CORRECT_TARGETS: "Tot. Correct Targets",
    TOTAL_CORRECT_COMPETENCIES: "Tot. Correct Competencies",
    TOTAL_TARGETS: "Tot. Targets",
    TARGET: "Target",
    TARGETS: "TARGETS",
    ACQUISITION_TARGETS: "Acquisition Target(s)",
    TOTAL_CORRECT_STEPS: "Tot. Correct Steps",
    TOTAL_STEPS: "Tot. Steps",
    TOTAL_PROMPTED_IN_VIEW: "Prompted In View",
    TOTAL_PROMPTED_OUT_OF_VIEW: "Prompted Out Of View",
    TOTAL_CORRECT_IN_VIEW: "Correct In View",
    TOTAL_CORRECT_OUT_OF_VIEW: "Correct Out Of View",
    SCORE: "Score",
    SELECTION: "Selection",
    QUANTITY: "Quantity",
    BEGIN_PROMPT: "Begin Prompt",
    END_PROMPT: "End Prompt",
    SUB_TARGET: "Sub-Target",
    SUB_TARGET_NOTES: "Sub-Target Notes",
    DISC_STEP: "Disc. Step",
    DISC_STEP_CHART_DISPLAY: 'Disc. Step Chart Display',
    NOTES: "Notes",
    DATASHEET_INDEX: "Datasheet Index",
    TOTAL_CORRECT_OPPORTUNITIES: "Tot. Correct Opportunties",
    TOTAL_OPPORTUNITIES: "Tot. Opportunties",
    SESSION_DATA: "Session Data",
    PROBLEM_BEHAVIORS: "Problem Behaviors",
    ID: "Id",
    PPROBLEMBX_ID: 'Problem Behavior Id',
    CONFLICT: 'Conflict',
    MET: 'Met',
    IN_PROGRESS: 'In Progress',
    SESSIONS: 'SESSIONS',
    BASELINE: 'Baseline',

    MASTERED_TARGETS: 'Mastered Targets',
    TREATMENT_HOURS: 'Treatment Hours',
};

module.exports = {
    chartTypeLabel,
    chartDataLabel,
};
