const NoTargetsMasteredSteps = [
    { id: 'af1e376c-feee-11ea-adc1-0242ac120002', name: 'MT Target 1 in Iso', display: 'MT [T1] in Iso', target: 1, class: ''},
    { id: 'af1e39a6-feee-11ea-adc1-0242ac120002', name: 'MT Target 1 vs Unknowns', display: 'MT [T1] vs Unknowns', target: 1, class: ''},
    { id: 'af1e3d84-feee-11ea-adc1-0242ac120002', name: 'MT Target 2 in Iso', display: 'MT [T2] in Iso', target: 2, class: ''},
    { id: 'af1e3e88-feee-11ea-adc1-0242ac120002', name: 'MT Target 2 vs Unknowns', display: 'MT [T2] vs Unknowns', target: 2, class: ''},
    { id: 'af1e40ea-feee-11ea-adc1-0242ac120002', name: 'MT Target 1 vs Target 2', display: 'MT [T1] vs [T2]', target: 1, class: ''},
    { id: 'af1e402c-feee-11ea-adc1-0242ac120002', name: 'MT Target 2 vs Target 1', display: 'MT [T2] vs [T1]', target: 2, class: ''},
    { id: 'af1e3f5a-feee-11ea-adc1-0242ac120002', name: 'RR Target 1 and Target 2', display: 'RR [T1] and [T2]', target: 'both', class: ''}
];

const TwoTargetsMasteredSteps = [
    { id: 'fb1c066c-feee-11ea-adc1-0242ac120002', name: 'MT Target 3+ in Iso', display: 'MT [T3] in Iso', target: 3, class: ''},
    { id: 'fb1c0590-feee-11ea-adc1-0242ac120002', name: 'MT Target 3+ vs Unknowns', display: 'MT [T3] vs Unknowns', target: 3, class: 'sub-check-box'},
    { id: 'fb1c048c-feee-11ea-adc1-0242ac120002', name: 'MT Target 3+ vs Knowns', display: 'MT [T3] vs Knowns', target: 3, class: 'sub-check-box'},
    { id: 'fb1c020c-feee-11ea-adc1-0242ac120002', name: 'RR Target 3+ and Mastered', display: 'RR [T3] and mastered', target: 3, class: ''}
];

const Lookup = {};

NoTargetsMasteredSteps.forEach((step) => {
    Lookup[step.id] = step;
});
TwoTargetsMasteredSteps.forEach((step) => {
    Lookup[step.id] = step;
});

module.exports = { 
    Lookup,
    NoTargetsMasteredSteps,
    TwoTargetsMasteredSteps
};
