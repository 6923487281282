export const antecedentHash = {
    'efff1ef6-c706-11ea-87d0-0242ac130003': 'Demand Given',
    'f7f44bf4-c706-11ea-87d0-0242ac130003': 'Prompted to Follow Demand',
    'fe45bde4-c706-11ea-87d0-0242ac130003': 'Denied Access to Tangible',
    '0ac022a8-c707-11ea-87d0-0242ac130003': 'Access to Tangible Removed',
    '1059f824-c707-11ea-87d0-0242ac130003': 'Attention Removed',
    '1616c68e-c707-11ea-87d0-0242ac130003': 'Attention Given',
    '1fd4fd80-c707-11ea-87d0-0242ac130003': 'Engaged in Activity'
};
export const consequenceHash = {
    '2e8fd1ce-c707-11ea-87d0-0242ac130003': 'Attention Given',
    '2e8fd408-c707-11ea-87d0-0242ac130003': 'Attention Removed',
    '2e8fd570-c707-11ea-87d0-0242ac130003': 'Escape Demand Removed',
    '2e8fd67e-c707-11ea-87d0-0242ac130003': 'Redirected to Other Activity',
    '43853e98-c707-11ea-87d0-0242ac130003': 'Bx Block',
    '43854028-c707-11ea-87d0-0242ac130003': 'Prompted Follow Through',
    '438540f0-c707-11ea-87d0-0242ac130003': 'Access to Tangible',
    '55a18e9c-c707-11ea-87d0-0242ac130003': 'Tangible Removed',
    '5a59c6c0-c707-11ea-87d0-0242ac130003': 'No Change in Environment'
}

export const reductionTypeDefaultLookup = {
    'Percent_Of_Intervals': { display: 'Percent of Intervals', value: 'Percent_Of_Intervals' },
    'Rate': { display: 'Rate', value: 'Rate' },
    'Frequency': { display: 'Frequency', value: 'Frequency' },
    'Percent_Of_Total_Duration': { display: 'Percent of Total Duration', value: 'Percent_Of_Total_Duration' },
    'Average_Duration': { display: 'Average Duration', value: 'Average_Duration' },
};

export const dataSheetsByReductionType = {
    'Percent_Of_Intervals': [
        { display: '- Select Data Sheet -', value: '' },
        { display: 'Momentary Time Sampling', value: 'MomentaryTimeSamplingDs' },
        { display: 'Partial Interval Recording', value: 'PartialIntervalRecordingDs' },
        { display: 'Partial Interval Recording + ABC Narrative', value: 'PartialIntervalRecordingAbcNarrativeDs' },
        { display: 'Partial Interval Recording + ABC Structured', value: 'PartialIntervalRecordingAbcStructuredDs' }
    ],
    'Rate': [
        { display: '- Select Data Sheet -', value: '' },
        { display: 'Event Recording + ABC Narrative', value: 'EventRecordingAbcNarrativeDs' },
        { display: 'Event Recording + ABC Structured', value: 'AbcStructuredDs' },
        { display: 'Event Recording - Rate', value: 'EventRecordingDs' }
    ],
    'Frequency': [
        { display: '- Select Data Sheet -', value: '' },
        { display: 'Event Recording - Frequency', value: 'EventRecordingFrequencyDs' }
    ],
    'Percent_Of_Total_Duration': [
        { display: '- Select Data Sheet -', value: '' },
        { display: 'Duration', value: 'DurationPbxDs' },
        { display: 'Event Recording + ABC Structured', value: 'AbcStructuredDs' }
    ],
    'Average_Duration': [
        { display: '- Select Data Sheet -', value: '' },
        { display: 'Duration', value: 'DurationPbxDs' },
        { display: 'Event Recording + ABC Structured', value: 'AbcStructuredDs' }
    ]
};
