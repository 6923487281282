import _cloneDeep from 'lodash/cloneDeep';
import { getTrialScoreAndSelection } from '../util';
import { antecedentHash, consequenceHash } from '../../../pages/admin/organizations/p-problem-bxes/details/components/data-sheet/constants';
import { Lookup } from '../../../pages/account/data-sheets/details/adaptive-bx-data-sheets/dtt/simultaneous/step-constants';
import { getTimeFormat, getRoundUpTime } from '../../timer-display';
import _isEmpty from 'lodash/isEmpty';
import Moment from 'moment';
import { lookup as DTTManualLookup } from '../../../pages/account/data-sheets/details/adaptive-bx-data-sheets/buttons';
import { chartDataLabel } from '../label';
import { DATA_SHEET_TYPES } from '../../../common/constants/data-sheets';
import { getTrialsWithoutDuplicate } from '../../../common/utils/data-sheets';

export const trialPrompts = ['p', 'fp', 'pp', 'fv', 'pv', 'fvi', 'pvi', 'fm', 'pm', 'fg', 'pg', 'fpo', 'ppo', 'lqs'];

export const requiredADxTrialFields = [
    'c', 'i', ...trialPrompts
];


export const hasPrompt = (trial) => {
    return trialPrompts.some(key => !!trial[key]);
}

export const hasADxTrial = (trial) => {
    return requiredADxTrialFields.some(key => !!trial[key]);
}

export const hasData = (dataSheet) => {
    const { PSdRId } = dataSheet;
    if (PSdRId) {
        return hasABxData(dataSheet);
    }
    else {
        return hasPBxData(dataSheet);
    }
}

const hasABxData = (dataSheet) => {
    const { Trials } = dataSheet
    for (let i = 0; i < Trials.length; i++) {
        const trial = Trials[i];
        if (hasADxTrial(trial))
            return true;
    }
    return false;
};

const hasPBxData = (dataSheet) => {
    const { ds, setting, Trials, observation_times } = dataSheet;

    // if there is no setting then we know it has no data
    if (setting === '') return false;

    switch (ds) {
        case "AbcStructuredDs":
        case "EventRecordingDs":
        case "EventRecordingAbcNarrativeDs":
        case "DurationPbxDs":
        case "EventRecordingFrequencyDs":
            // If array.some return false that means all observation times have both start and end times so we negate it
            return observation_times.length > 0 && !observation_times.some(item => !item.start_time || !item.end_time);

        case "PartialIntervalRecordingDs":
        case "PartialIntervalRecordingAbcNarrativeDs":
        case "PartialIntervalRecordingAbcStructuredDs":
        case "MomentaryTimeSamplingDs":
            return Trials.length > 0;   // it has data as long as there are trials

        default:
            return false;
    }
}

const getTotalObservation = (obj, timeUnit) => {
    let totalObservation = 0;
    let totalObservationDisplay = 0;
    obj.observation_times.forEach((item) => {
        const { start_time, end_time } = item;
        const dsStart = Moment(start_time);
        const dsEnd = Moment(end_time);

        const observation = dsEnd.diff(dsStart, timeUnit, true);
        if (!isNaN(totalObservation)) totalObservation += observation;

        const observationDisplay = dsEnd.diff(dsStart, 'milliseconds');
        if (!isNaN(observationDisplay)) totalObservationDisplay += getRoundUpTime(observationDisplay);
    }, 0);

    return {
        totalObservation,
        totalObservationDisplay
    }
}

export const calculateABx = (dataSheet, dataSheetIndex, result, pTargetsLookup, pTargetsById, entities, timeUnit) => {
    // console.log('calculate abx', dataSheet.ds, dataSheet, dataSheetIndex, result, pTargetsLookup, entities);
    switch (dataSheet.ds) {
        case 'CompetencyChecklistDs':
        case 'MultipleTargetDs':
        case 'TaskAnalysisDs':
            return adxStats.calculateMultipleTarget(dataSheet, dataSheetIndex, pTargetsLookup);
        case 'TaskAnalysisWithSubtargetsDs':
            return adxStats.calculateTaskAnalysisWithSubtargets(dataSheet, dataSheetIndex, pTargetsLookup);
        case 'TrialByTrialDs':
            return adxStats.calculateTrialByTrial(dataSheet, dataSheetIndex, pTargetsLookup);
        case 'FirstTrialCorrectDs':
        case 'ProbingDs':
            return adxStats.calculateFirstTrial(dataSheet, dataSheetIndex, pTargetsLookup);
        case 'LatencyDs':
        case 'DurationDs':
            return adxStats.calculateLatency(dataSheet, dataSheetIndex, timeUnit, pTargetsLookup);
        case 'MandingDs':
            return adxStats.calculateManding(dataSheet, timeUnit, pTargetsLookup, dataSheetIndex);
        case 'GeneralizationDs':
            return adxStats.calculateGeneralization(dataSheet, dataSheetIndex, pTargetsById);
        case 'OpportunityDs':
            return adxStats.calculateOpportunity(dataSheet, dataSheetIndex, pTargetsLookup);
        case 'EchoicDs':
            return adxStats.calculateEchoic(dataSheet, dataSheetIndex, pTargetsById);
        case 'DTTDs':
            return adxStats.calculateDTT(dataSheet, dataSheetIndex, result, pTargetsLookup, entities);
        default:
            return null;
    }
}

export const adxStats = (function () {
    const calculateDTT = (dataSheet, dataSheetIndex, result, pTargetsLookup) => {
        // because multiple targets are allowed and we need to keep track of disc. step
        // 1) separate trials into multiple buckets and process it one by one but we only store the index to the data
        const isMaintenance = dataSheet.MaintenancePSdRId;
        const manualLookup = DTTManualLookup(isMaintenance);
        let dataSheetsPTargetsById = {};
        const pTargetsLookupByName = { Target1: { lesson_place: -1, names: [] }, Target2: { lesson_place: -1, names: [] } };
        let hasMoreThanOnePTarget = false;
        const trialsByTarget = {};
        const trials = dataSheet.Trials;

        trials.forEach((trial, i) => {
            // if (!trial.c && !trial.i && !trial.p) return;   // no selection for the trial so we ignore it
            const pTargetId = trial.PTargetId;
            if (!trialsByTarget[pTargetId]) trialsByTarget[pTargetId] = [];
            trialsByTarget[pTargetId].push(i);
        });
        
        if (dataSheet.DataSheetsPTargets) {
            const dataSheetsPTargets = dataSheet.DataSheetsPTargets;
            hasMoreThanOnePTarget = dataSheetsPTargets.length > 1;

            // First look at ds_init_values for the target
            if (!_isEmpty(dataSheet.ds_init_values)) {
                const { target1Id, target2Id } = dataSheet.ds_init_values;

                if (target1Id) {
                    const pTargetId = target1Id;
                    if (pTargetsLookup[pTargetId]) {
                        const pTarget = pTargetsLookup[pTargetId];
                        pTargetsLookupByName.Target1.names.push(pTarget.name);
                        pTargetsLookupByName.Target1.lesson_place = pTarget.lesson_place;
                    }
                }

                if (target2Id) {
                    const pTargetId = target2Id;
                    if (pTargetsLookup[pTargetId]) {
                        const pTarget = pTargetsLookup[pTargetId];
                        pTargetsLookupByName.Target2.names.push(pTarget.name);
                        pTargetsLookupByName.Target2.lesson_place = pTarget.lesson_place;
                    }
                }
            }
            // if it's empty then we'll look at dataSheetsPTargets
            else if (dataSheetsPTargets.length > 0) {
                dataSheetsPTargets.forEach((dsPT, dsPTIndex) => {
                    if (!pTargetsLookup[dsPT.PTargetId]) return;
                    const pTarget = pTargetsLookup[dsPT.PTargetId];
                    if (dsPTIndex === 0) {
                        pTargetsLookupByName.Target1.names.push(pTarget.name);
                        pTargetsLookupByName.Target1.lesson_place = pTarget.lesson_place;
                    }
                    else if (typeof trialsByTarget[pTarget.id] !== 'undefined') {
                        pTargetsLookupByName.Target2.names.push(pTarget.name);
                        pTargetsLookupByName.Target2.lesson_place = pTarget.lesson_place;
                    }
                });
            }
        }

        const t1Place = pTargetsLookupByName.Target1.lesson_place !== -1 ? pTargetsLookupByName.Target1.lesson_place + 1 : '1';
        const t2Place = pTargetsLookupByName.Target2.lesson_place !== -1 ? pTargetsLookupByName.Target2.lesson_place + 1 : '2';

        // For DTT Manual, when we do MT Target 2 in iso for only one target, pTargetsLookupByName.Target1 will have value but not pTargetsLookupByName.Target2
        // However we need pTargetsLookupByName.Target2 to have a value for acquisition target so we should copy Target1 to Target2
        if (pTargetsLookupByName.Target2.lesson_place === -1) {
            pTargetsLookupByName.Target2 = _cloneDeep(pTargetsLookupByName.Target1);
        }

        // 2) we can now safely go through each target trials
        Object.keys(trialsByTarget).forEach(k => {
            const trialIndexList = trialsByTarget[k];
            if (!result[k]) result[k] = [];
            let acquisitionTargets = [];
            let currentDiscStep = '';
            let currentDiscStepDisplay = '';
            let totalCorrectTrials = 0;
            let totalTrials = 0;
            trialIndexList
                .map(trialIndex => trials[trialIndex])
                .filter(trial => hasADxTrial(trial))
                .forEach((trial, i, arr) => {
                    let discStep = '';
                    let discStepDisplay = '';
                    let target = '';

                    if (Lookup[trial.SubTargetId]) {
                        discStep = Lookup[trial.SubTargetId].display.replace('[T1]', `Target ${t1Place}`).replace('[T2]', `Target ${t2Place}`).replace('[T3]', `Target ${t1Place}`);
                        discStepDisplay = Lookup[trial.SubTargetId].display;
                        target = Lookup[trial.SubTargetId].target;
                    }
                    else if (!_isEmpty(trial.disc_step)) {
                        discStep = trial.disc_step.replace('T 1', `Target ${t1Place}`).replace('T 2', `Target ${t2Place}`).replace('RR T (3+)', `Target ${t1Place}`);
                        discStepDisplay = trial.disc_step;
                        target = manualLookup[trial.disc_step].target;
                    }

                    if (currentDiscStep !== discStep) {
                        if (currentDiscStep !== '') {
                            let tempDiscStepDisplay = currentDiscStepDisplay;
                            const primaryTargetName = pTargetsLookup[k] ? pTargetsLookup[k].name : "";
                            const isTarget1Primary = pTargetsLookupByName.Target1.names.length > 0 && pTargetsLookupByName.Target1.names[0] === primaryTargetName;

                            // if it's T3
                            if (tempDiscStepDisplay.indexOf('[T3]') > -1 || tempDiscStepDisplay.indexOf('T (3+)') > -1) {
                                // let combinedTargets = [...pTargetsLookupByName.Target1, ...pTargetsLookupByName.Target2];

                                // DTT sim
                                if (tempDiscStepDisplay.indexOf('[T3]') > -1) {
                                    tempDiscStepDisplay = tempDiscStepDisplay.replace('[T3]', pTargetsLookupByName.Target1.names.join(', '));
                                }
                                // DTT manual
                                else if (tempDiscStepDisplay.indexOf('T (3+)') > -1) {
                                    tempDiscStepDisplay = tempDiscStepDisplay.replace('T (3+)', pTargetsLookupByName.Target1.names.join(', '));
                                }

                                acquisitionTargets = [...acquisitionTargets, ...pTargetsLookupByName.Target1.names, ...pTargetsLookupByName.Target2.names];
                            }
                            else {
                                if (pTargetsLookupByName.Target1.names.length > 0 && (tempDiscStepDisplay.indexOf('[T1]') > -1 || tempDiscStepDisplay.indexOf('T 1') > -1)) {
                                    // If it's DTT sim
                                    tempDiscStepDisplay = tempDiscStepDisplay.replace('[T1]', pTargetsLookupByName.Target1.names.join(", "));

                                    // if it's DTT manual
                                    tempDiscStepDisplay = tempDiscStepDisplay.replace('T 1', pTargetsLookupByName.Target1.names.join(", "));

                                    if (target === 1 || target === 'both') {
                                        acquisitionTargets = [...acquisitionTargets, ...pTargetsLookupByName.Target1.names];
                                    }
                                }

                                if (pTargetsLookupByName.Target2.names.length > 0 && (tempDiscStepDisplay.indexOf('[T2]') > -1 || tempDiscStepDisplay.indexOf('T 2') > -1)) {
                                    // If it's DTT sim
                                    tempDiscStepDisplay = tempDiscStepDisplay.replace('[T2]', pTargetsLookupByName.Target2.names.join(", "));

                                    // if it's DTT manual
                                    tempDiscStepDisplay = tempDiscStepDisplay.replace('T 2', pTargetsLookupByName.Target2.names.join(", "));

                                    if (target === 2 || target === 'both') {
                                        acquisitionTargets = [...acquisitionTargets, ...pTargetsLookupByName.Target2.names];
                                    }
                                }

                                // if we're out here and [T1] and [T2] hasn't been replaced then update them
                                // If it's DTT sim
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('[T1]', 'Target 1');
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('[T2]', 'Target 2');

                                // if it's DTT manual
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('T 2', 'Target 2');
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('T 1', 'Target 1');
                            }

                            const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

                            // we have enough data for a disc step so push it to result                        
                            result[k].push({
                                [chartDataLabel.ID]: dataSheet.id,
                                [chartDataLabel.TARGET]: pTargetsLookup[k] ? pTargetsLookup[k].name : "",
                                [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
                                [chartDataLabel.SESSION]: sequence,
                                [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
                                [chartDataLabel.INITIALS]: dataSheet.initials,
                                [chartDataLabel.DISC_STEP]: currentDiscStep,
                                [chartDataLabel.DISC_STEP_CHART_DISPLAY]: tempDiscStepDisplay,
                                [chartDataLabel.TOTAL_CORRECT_TRIALS]: totalCorrectTrials,
                                [chartDataLabel.TOTAL_TRIALS]: totalTrials,
                                [chartDataLabel.NOTES]: dataSheet.notes,
                                [chartDataLabel.CONFLICT]: dataSheet.conflict,
                                [chartDataLabel.ACQUISITION_TARGETS]: acquisitionTargets,
                            });
                        }

                        currentDiscStep = discStep;
                        currentDiscStepDisplay = discStepDisplay;
                        totalCorrectTrials = 0;
                        totalTrials = 0;
                        acquisitionTargets = [];
                    }

                    totalCorrectTrials += trial.c === true ? 1 : 0;
                    totalTrials += 1;

                    if (i + 1 >= arr.length) {
                        let tempDiscStepDisplay = currentDiscStepDisplay;

                        const primaryTargetName = pTargetsLookup[k] ? pTargetsLookup[k].name : "";
                        const isTarget1Primary = pTargetsLookupByName.Target1.names.length > 0 && pTargetsLookupByName.Target1.names[0] === primaryTargetName;

                        // if it's T3
                        if (tempDiscStepDisplay.indexOf('[T3]') > -1 || tempDiscStepDisplay.indexOf('T (3+)') > -1) {
                            // let combinedTargets = [...pTargetsLookupByName.Target1, ...pTargetsLookupByName.Target2];

                            // DTT sim
                            if (tempDiscStepDisplay.indexOf('[T3]') > -1) {
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('[T3]', pTargetsLookupByName.Target1.names.join(', '));
                            }
                            // DTT manual
                            else if (tempDiscStepDisplay.indexOf('T (3+)') > -1) {
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('T (3+)', pTargetsLookupByName.Target1.names.join(', '));
                            }

                            acquisitionTargets = [...acquisitionTargets, ...pTargetsLookupByName.Target1.names, ...pTargetsLookupByName.Target2.names];
                        }
                        else {
                            if (pTargetsLookupByName.Target1.names.length > 0 && (tempDiscStepDisplay.indexOf('[T1]') > -1 || tempDiscStepDisplay.indexOf('T 1') > -1)) {
                                // If it's DTT sim
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('[T1]', pTargetsLookupByName.Target1.names.join(", "));

                                // if it's DTT manual
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('T 1', pTargetsLookupByName.Target1.names.join(", "));

                                if (target === 1 || target === 'both') {
                                    acquisitionTargets = [...acquisitionTargets, ...pTargetsLookupByName.Target1.names];
                                }
                            }

                            if (pTargetsLookupByName.Target2.names.length > 0 && (tempDiscStepDisplay.indexOf('[T2]') > -1 || tempDiscStepDisplay.indexOf('T 2') > -1)) {
                                // If it's DTT sim
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('[T2]', pTargetsLookupByName.Target2.names.join(", "));

                                // if it's DTT manual
                                tempDiscStepDisplay = tempDiscStepDisplay.replace('T 2', pTargetsLookupByName.Target2.names.join(", "));

                                if (target === 2 || target === 'both') {
                                    acquisitionTargets = [...acquisitionTargets, ...pTargetsLookupByName.Target2.names];
                                }
                            }

                            // if we're out here and [T1] and [T2] hasn't been replaced then update them
                            // If it's DTT sim
                            tempDiscStepDisplay = tempDiscStepDisplay.replace('[T1]', 'Target 1');
                            tempDiscStepDisplay = tempDiscStepDisplay.replace('[T2]', 'Target 2');

                            // if it's DTT manual
                            tempDiscStepDisplay = tempDiscStepDisplay.replace('T 2', 'Target 2');
                            tempDiscStepDisplay = tempDiscStepDisplay.replace('T 1', 'Target 1');
                        }

                        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

                        // we have enough data for a disc step so push it to result                        
                        result[k].push({
                            [chartDataLabel.ID]: dataSheet.id,
                            [chartDataLabel.TARGET]: pTargetsLookup[k] ? pTargetsLookup[k].name : "",
                            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
                            [chartDataLabel.SESSION]: sequence,
                            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
                            [chartDataLabel.INITIALS]: dataSheet.initials,
                            [chartDataLabel.DISC_STEP]: currentDiscStep,
                            [chartDataLabel.DISC_STEP_CHART_DISPLAY]: tempDiscStepDisplay,
                            [chartDataLabel.TOTAL_CORRECT_TRIALS]: totalCorrectTrials,
                            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
                            [chartDataLabel.NOTES]: dataSheet.notes,
                            [chartDataLabel.CONFLICT]: dataSheet.conflict,
                            [chartDataLabel.ACQUISITION_TARGETS]: acquisitionTargets,
                        });
                    }
                });
        });
    };

    const calculateEchoic = (dataSheet, dataSheetIndex, pTargetsById) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;
        dataSheet.Trials.forEach((trial, i) => {
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) targets[pTargetId] = { dsPTargetKey: '', part: '', trialQuantity: 0, trialScore: '', totalCorrectTrials: 0, totalTrials: 0 };
            const target = targets[pTargetId];
            const trialScoreAndDisplay = getTrialScoreAndSelection(trial);
            target.trialQuantity = trialScoreAndDisplay.quantity;
            target.trialScore = trialScoreAndDisplay.score;
            target.dsPTargetKey = trial.DataSheetId + "-" + trial.PTargetId;
            target.totalCorrectTrials += trial.c === true ? 1 : 0;
            target.totalTrials += 1;

            // when calculate stats on the server, we don't need to do this
            if (pTargetsById) {
                const pTarget = pTargetsById[pTargetId];
                const { sub_targets, sub_targets_history } = pTarget;
                target.part = pTarget.name; // set target part to be pTarget.name

                const subTargetLookup = {};
                const subTargets = sub_targets_history[dataSheet.PSdRsDataSheetId] || sub_targets;
                subTargets.forEach(st => {
                    subTargetLookup[st.id] = st;
                });

                // Look through history and find all the previous sub-targets
                pTarget.history.forEach(h => {
                    if (!h.sub_targets || h.sub_targets.length === 0) return;
                    h.sub_targets.forEach(st => {
                        subTargetLookup[st.id] = st;
                    });
                })

                // if trial has SubTargetId then we know it's a subTarget
                if (trial.SubTargetId && subTargetLookup[trial.SubTargetId]) {
                    const st = subTargetLookup[trial.SubTargetId];
                    target.part = st.target || st.name;
                }
            }
        });

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateGeneralization = (dataSheet, dataSheetIndex, pTargetsById) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;
        dataSheet.Trials.forEach((trial, i) => {
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) targets[pTargetId] = { dsPTargetKey: '', part: '', trialQuantity: 0, trialScore: '', notes: '', totalCorrectTrials: 0, totalTrials: 0 };
            const target = targets[pTargetId];
            const trialScoreAndDisplay = getTrialScoreAndSelection(trial);
            target.trialQuantity = trialScoreAndDisplay.quantity;
            target.trialScore = trialScoreAndDisplay.score;
            target.dsPTargetKey = trial.DataSheetId + "-" + trial.PTargetId;
            target.notes = trial.notes;
            const correctTrial = trial.c === true;
            target.totalCorrectTrials += correctTrial ? 1 : 0;
            target.totalTrials += 1;

            // when calculate stats on the server, we don't need to do this
            if (pTargetsById) {
                const pTarget = pTargetsById[pTargetId];
                const { sub_targets, sub_targets_history } = pTarget;
                const subTargets = sub_targets_history[dataSheet.PSdRsDataSheetId] || sub_targets;

                target.part = pTarget.name; // set target part to be pTarget.name

                const subTargetLookup = {};
                subTargets.forEach(st => {
                    subTargetLookup[st.id] = st;
                });

                // Look through history and find all the previous sub-targets
                pTarget.history.forEach(h => {
                    if (!h.sub_targets || h.sub_targets.length === 0) return;
                    h.sub_targets.forEach(st => {
                        subTargetLookup[st.id] = st;
                    });
                })

                // if trial has SubTargetId then we know it's a subTarget
                if (trial.SubTargetId && subTargetLookup[trial.SubTargetId]) {
                    const st = subTargetLookup[trial.SubTargetId];
                    target.part = st.target || st.name;
                }

                // // if trial has SubTargetId then we know it's a subTarget
                // if (trial.SubTargetId) {
                //     target.part = pTarget.sub_targets.reduce((prev, curr, i) => {
                //         // we're updating SubTarget.target to be SubTarget.name so check for either
                //         if (trial.SubTargetId === curr.id) prev = curr.target || curr.name;
                //         return prev;
                //     }, '');
                // }
            }
        });

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateFirstTrial = (dataSheet, dataSheetIndex, pTargetsLookup) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;

        dataSheet.Trials.forEach((trial, i) => {
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) targets[pTargetId] = { trialQuantity: 0, trialScore: '', beginPrompt: trial.ftc_p_begin, endPrompt: trial.ftc_p_end, totalCorrectTrials: 0, totalTrials: 0 };
            const target = targets[pTargetId];
            const trialScoreAndDisplay = getTrialScoreAndSelection(trial);
            target.trialQuantity = trialScoreAndDisplay.quantity;
            target.trialScore = trialScoreAndDisplay.score;
            target.name = pTargetsLookup[pTargetId] ? pTargetsLookup[pTargetId].name : "";
            const correctTrial = trial.c === true;
            target.totalCorrectTrials += correctTrial ? 1 : 0;
            target.totalTrials += 1;
        });

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateLatency = (dataSheet, dataSheetIndex, timeUnit, pTargetsLookup) => {
        let totalDuration = 0;
        let totalDurationDisplay = 0;
        let totalCorrectTrials = 0;
        let totalTrials = 0;

        const targets = {};    // contains all targets data and use id to lookup
        dataSheet.Trials.forEach((trial, j) => {
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            const start = Moment(trial.start_time);
            const end = Moment(trial.end_time);
            const diff = end.diff(start, timeUnit, true);
            const displayDiff = end.diff(start, 'milliseconds');
            totalDuration += isNaN(diff) ? 0 : diff;
            totalDurationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);
            totalCorrectTrials += trial.c === true ? 1 : 0;

            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) {
                targets[pTargetId] = { display: pTargetsLookup[pTargetId] ? pTargetsLookup[pTargetId].name : "", totalDuration: 0, totalDurationDisplay: 0, averageDuration: 0, averageDurationDisplay: 0, totalCorrectTrials: 0, totalTrials: 0, percentage: 0 }
            }
            targets[pTargetId].totalCorrectTrials += trial.c === true ? 1 : 0;
            targets[pTargetId].totalTrials += 1;
            targets[pTargetId].totalDuration += isNaN(diff) ? 0 : diff;
            targets[pTargetId].totalDurationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);
        });

        const timeUnitFirstChar = timeUnit.substring(0, 1);

        Object.keys(targets).forEach(id => {
            const target = targets[id];
            target.percentage = Math.round(target.totalTrials === 0 ? 0 : (target.totalCorrectTrials / target.totalTrials) * 100);
            target.averageDuration = (target.totalTrials === 0 ? 0 : target.totalDuration / target.totalTrials).toFixed(2);
            target.averageDurationDisplay = target.totalTrials === 0 ? 0 : target.totalDurationDisplay / target.totalTrials;

            target.averageDurationDisplay = `${getTimeFormat(target.averageDurationDisplay)} [${target.averageDuration} ${timeUnitFirstChar}]`;

            target.totalDuration = target.totalDuration.toFixed(2);
            target.totalDurationDisplay = getTimeFormat(target.totalDurationDisplay);
        });

        const averageDuration = totalTrials === 0 ? 0 : totalDuration / totalTrials;
        const averageDurationDisplay = totalTrials === 0 ? 0 : totalDurationDisplay / totalTrials;
        const percentage = Math.round(totalTrials === 0 ? 0 : (totalCorrectTrials / totalTrials) * 100);

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_DURATION_TIME]: totalDuration.toFixed(2),
            [chartDataLabel.TOTAL_DURATION_DISPLAY]: getTimeFormat(totalDurationDisplay),
            [chartDataLabel.AVERAGE_DURATION_TIME]: averageDuration.toFixed(2),
            [chartDataLabel.AVERAGE_DURATION_DISPLAY]: `${getTimeFormat(averageDurationDisplay)} [${averageDuration.toFixed(2)} ${timeUnitFirstChar}]`,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.TOTAL_CORRECT_TRIALS]: totalCorrectTrials,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
            [chartDataLabel.TARGETS]: targets,
        };
    };

    const calculateManding = (dataSheet, timeUnit, pTargetsLookup, dataSheetIndex) => {
        const { totalObservation, totalObservationDisplay } = getTotalObservation(dataSheet, timeUnit);
        const totalDuration = totalObservation;

        let totalTrials = 0;
        let totalCorrectInView = 0;
        let totalCorrectOutOfView = 0;
        let totalPromptedInView = 0;
        let totalPromptedOutOfView = 0;
        const targets = {};    // contains all targets data and use id to lookup
        dataSheet.Trials.forEach((trial, i) => {
            // if (!trial.c && !trial.i && !trial.p) return;   // no selection for the trial so we ignore it
            ++totalTrials;
            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) targets[pTargetId] = { display: pTargetsLookup[pTargetId] ? pTargetsLookup[pTargetId].name : "", totalCorrectTrials: 0, totalTrials: 0 };
            const target = targets[pTargetId];
            const correctTrial = trial.c === true;
            const outOfView = trial.out_of_view === true;
            target.totalCorrectTrials += correctTrial ? 1 : 0;
            target.totalTrials += 1;
            if (correctTrial) {
                totalCorrectInView += !outOfView ? 1 : 0;
                totalCorrectOutOfView += outOfView ? 1 : 0;
            }
            else {
                totalPromptedInView += !outOfView ? 1 : 0;
                totalPromptedOutOfView += outOfView ? 1 : 0;
            }
        });

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_DURATION_TIME]: totalDuration,
            [chartDataLabel.TOTAL_CORRECT_IN_VIEW]: totalCorrectInView,
            [chartDataLabel.TOTAL_CORRECT_OUT_OF_VIEW]: totalCorrectOutOfView,
            [chartDataLabel.TOTAL_PROMPTED_IN_VIEW]: totalPromptedInView,
            [chartDataLabel.TOTAL_PROMPTED_OUT_OF_VIEW]: totalPromptedOutOfView,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateMultipleTarget = (dataSheet, dataSheetIndex, pTargetsLookup) => {
        let totalCorrectTargets = 0;
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;
        dataSheet.Trials.forEach((trial, i) => {
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) targets[pTargetId] = { display: pTargetsLookup[pTargetId] ? pTargetsLookup[pTargetId].name : "", totalCorrectTrials: 0, totalTrials: 0, trialQuantity: 0, trialScore: '' };
            const target = targets[pTargetId];
            const correctTrial = trial.c === true;
            target.totalCorrectTrials += correctTrial ? 1 : 0;
            target.totalTrials += 1;
            const trialScoreAndSelection = getTrialScoreAndSelection(trial);
            target.trialQuantity = trialScoreAndSelection.quantity;
            target.trialScore = trialScoreAndSelection.score;
            totalCorrectTargets += correctTrial ? 1 : 0;
            ++totalTrials;
        });

        const percentage = Math.round(totalTrials === 0 ? 0 : (totalCorrectTargets / totalTrials) * 100);
        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_CORRECT_TARGETS]: totalCorrectTargets,
            [chartDataLabel.TOTAL_TARGETS]: totalTrials,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateTaskAnalysisWithSubtargets = (dataSheet, dataSheetIndex, pTargetsLookup) => {
        // TARGET: { id: 123, display: "Penny", sub_targets: { id: { part: "P", score: "C" } } }
        let totalCorrectTargets = 0;
        let totalTrials = 0;
        const target = {};
        dataSheet.Trials.forEach((trial, i) => {
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            const pTargetId = trial.PTargetId;
            const pTarget = pTargetsLookup[pTargetId];

            // temp fix for now, SUP is able to delete target if that target is being used in active session, can end up deleting a target with trials
            if (!pTarget) return;

            if (!target.id) {
                target.id = pTargetId,
                    target.display = pTarget.name,
                    target.sub_targets = {}
                target.totalCorrectTrials = 0;
                target.totalTrials = 0;
            }
            const correctTrial = trial.c === true;
            target.totalCorrectTrials += correctTrial ? 1 : 0;
            target.totalTrials += 1;
            totalCorrectTargets += correctTrial ? 1 : 0;
            ++totalTrials;

            if (pTargetsLookup) {
                const subTargetLookup = {};
                const { sub_targets, sub_targets_history } = pTarget;
                const subTargets = sub_targets_history[dataSheet.PSdRsDataSheetId] || sub_targets;

                subTargets.forEach(st => {
                    subTargetLookup[st.id] = st;
                });
                if (trial.SubTargetId && subTargetLookup[trial.SubTargetId]) {
                    const st = subTargetLookup[trial.SubTargetId];
                    if (!target.sub_targets[st.id]) {
                        target.sub_targets[st.id] = { part: st.name, score: correctTrial ? "C" : "I" }
                    }
                }
            }
        });

        const percentage = Math.round(totalTrials === 0 ? 0 : (totalCorrectTargets / totalTrials) * 100);

        let notes = dataSheet.notes.split(' ').splice(0, 2).join(' ');
        if (!_isEmpty(notes)) notes += '...';

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_CORRECT_TARGETS]: totalCorrectTargets,
            [chartDataLabel.TOTAL_TARGETS]: totalTrials,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.TARGET]: target,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateOpportunity = (dataSheet, dataSheetIndex, pTargetsLookup) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;
        dataSheet.Trials.forEach((trial, i) => {
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) targets[pTargetId] = { display: pTargetsLookup[pTargetId] ? pTargetsLookup[pTargetId].name : "", totalCorrectTrials: 0, totalTrials: 0 };
            targets[pTargetId].totalCorrectTrials += trial.c === true ? 1 : 0;
            targets[pTargetId].totalTrials += 1;
        });

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateTrialByTrial = (dataSheet, dataSheetIndex, pTargetsLookup) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;
        dataSheet.Trials.forEach((trial, i) => {
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            const pTargetId = trial.PTargetId;
            if (!targets[pTargetId]) {
                targets[pTargetId] = { display: pTargetsLookup[pTargetId] ? pTargetsLookup[pTargetId].name : "", totalCorrectTrials: 0, totalTrials: 0 };
            }
            targets[pTargetId].totalCorrectTrials += trial.c === true ? 1 : 0;
            targets[pTargetId].totalTrials += 1;
        });

        const sequence = `${dataSheet.sequence}${dataSheet.manual ? 'M' : ''}${dataSheet.conflict ? 'C' : ''}`;

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: dataSheet.conflict,
        };
    };

    const calculateProbing = (result, dataSheet, dataSheetIndex, pTargetsLookup) => {
        const hasProbingData = dataSheet.DataSheetsPTargets
            .reduce((hasProbingData, dsPTarget) => {
                return hasProbingData || dsPTarget.dataValues.PTarget.dataValues.probing_sub_targets
                    .flat()
                    .reduce((dataSheetHasProbing, probe) => {
                        if (dataSheetHasProbing) return true;
                        const { probed_date } = probe;
                        if (!probed_date) return false;
                        const probedDate = new Date(probed_date);
                        const { createdAt, complete_time } = dataSheet;
                        return probedDate > new Date(createdAt) && probedDate < new Date(complete_time);
                    }, false);
            }, false);
        return { hasProbingData };
    };

    return {
        calculateDTT,
        // calculateDuration, // same as Latency
        calculateEchoic,
        calculateFirstTrial,
        calculateLatency,
        calculateManding,
        calculateMultipleTarget,    // This is used for CompetencyCheckList as well
        calculateOpportunity,
        calculateTrialByTrial,
        calculateGeneralization,
        calculateTaskAnalysisWithSubtargets,
        calculateProbing
    };
})();

export const calculatePBx = ({ dataSheet, dataSheetIndex, timeUnit, pBxLookup, antecedentsLookup, pProblemBxId, ds, trials, isRbx, rbxDs }) => {
    // console.log('calculate abx', dataSheet.ds, dataSheet, dataSheetIndex, result, pTargetsLookup, entities);
    switch (ds) {
        case 'PartialIntervalRecordingDs':
        case 'PartialIntervalRecordingAbcNarrativeDs':
            return pbxStats.calculatePartialIntervalRecordingNew(dataSheet, dataSheetIndex, pBxLookup, pProblemBxId, trials, isRbx, rbxDs);
            // return pbxStats.calculatePartialIntervalRecording(dataSheet, dataSheetIndex, pBxLookup, pProblemBxId);
        case 'PartialIntervalRecordingAbcStructuredDs':
            return pbxStats.calculatePartialIntervalRecordingABCStructuredNew(dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId, trials, isRbx, rbxDs);
            // return pbxStats.calculatePartialIntervalRecordingABCStructured(dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId);
        case 'MomentaryTimeSamplingDs':
            return pbxStats.calculateMomentaryTimeSamplingNew(dataSheet, dataSheetIndex, pProblemBxId, trials, isRbx, rbxDs);
            // return pbxStats.calculateMomentaryTimeSampling(dataSheet, dataSheetIndex, pBxLookup, pProblemBxId);
        case 'DurationPbxDs':
            return pbxStats.calculateDurationNew(dataSheet, dataSheetIndex, timeUnit, pProblemBxId, trials, isRbx, rbxDs);
            // return pbxStats.calculateDuration(dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId);
        case 'EventRecordingFrequencyDs':
            return pbxStats.calculateEventRecordingFrequencyNew(dataSheet, dataSheetIndex, timeUnit, pProblemBxId, trials, isRbx, rbxDs);
            // return pbxStats.calculateEventRecordingFrequency(dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId);
        case 'EventRecordingDs':
        case 'EventRecordingAbcNarrativeDs':
            return pbxStats.calculateEventRecordingNew(dataSheet, dataSheetIndex, timeUnit, pProblemBxId, trials, isRbx, rbxDs);
            // return pbxStats.calculateEventRecording(dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId);
        case 'AbcStructuredDs':
            return pbxStats.calculateABCStructuredNew(dataSheet, dataSheetIndex, timeUnit, pBxLookup, antecedentsLookup, pProblemBxId, trials, isRbx, rbxDs);
            // return pbxStats.calculateABCStructured(dataSheet, dataSheetIndex, timeUnit, pBxLookup, antecedentsLookup, pProblemBxId);
        default:
            return null;
    }
}

export const calculateRBxABx = ({ ds, dataSheet, dataSheetIndex, pProblemBxId, trials, rbx }) => {
    switch (ds) {
        case DATA_SHEET_TYPES.TRIAL_BY_TRIAL:
            return pbxStats.calculateTrialByTrial(dataSheet, dataSheetIndex, pProblemBxId, trials, rbx.id, rbx.name);
        case DATA_SHEET_TYPES.FIRST_TRIAL:
            return pbxStats.calculateFirstTrial(dataSheet, dataSheetIndex, pProblemBxId, trials, rbx.id, rbx.name);
        case DATA_SHEET_TYPES.OPPORTUNITY:
            return pbxStats.calculateOpportunity(dataSheet, dataSheetIndex, pProblemBxId, trials, rbx.id, rbx.name);
        default:
            return null;
    }
}

export const pbxStats = (function () {
    // DEPRECATED
    const calculateABCStructured = (dataSheet, dataSheetIndex, timeUnit, pBxLookup, antecedentsLookup, pProblemBxId) => {
        const _antecedentHash = antecedentsLookup || antecedentHash;
        // const dsStart = Moment(dataSheet.start_time);
        // const dsEnd = Moment(dataSheet.end_time);

        // let totalObservation = dsEnd.diff(dsStart, timeUnit, true);
        // if (isNaN(totalObservation)) totalObservation = 0;

        // const totaObservationDisplayDiffInMS = dsEnd.diff(dsStart, 'milliseconds');
        // const totalObservationDisplay = isNaN(totaObservationDisplayDiffInMS) ? 0 : getRoundUpTime(totaObservationDisplayDiffInMS);
        const { totalObservation, totalObservationDisplay } = getTotalObservation(dataSheet, timeUnit);

        const antecedentObj = {}
        let totalDuration = 0;
        let totalDurationDisplay = 0;
        let totalOccurred = 0;

        let pbxes = {}; // because we're separating pbx and its replacement behaviors we will create a lookup and go through trials to sort it out

        // if (dataSheet.PProblemBxes) {
        //     dataSheet.PProblemBxes.forEach((id) => {
        //         pbxes[id] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(0), [chartDataLabel.RATE]: 0, type: 'behavior', display: pBxLookup[id] };
        //     })
        // }
        // Reason for replacing the code block above with the line below: utils.js is already looping through dataSheet.PProblemBxes, if we loop again in here, it will be nested loop and is looping through the same thing twice
        pbxes[pProblemBxId] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(0), [chartDataLabel.RATE]: 0, type: 'behavior', display: pBxLookup[pProblemBxId] };

        dataSheet.Trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only

            let allPbxes = [{ id: trial.PProblemBxId, type: 'behavior' }, ...trial.abc_structured_sub_b.map(id => {
                return { id, type: 'sub-behavior' };
            })];

            if (Array.isArray(trial.abc_structured_replacement_b) && trial.abc_structured_replacement_b.length !== 0) {
                // if there are replacement behaviors, we only want to count those
                allPbxes = trial.abc_structured_replacement_b.map(id => {
                    return { id, type: 'replacement-behavior' };
                });
            }

            const start = Moment(trial.start_time);
            const end = Moment(trial.end_time);
            const diff = end.diff(start, timeUnit, true);
            const displayDiff = end.diff(start, 'milliseconds');
            totalDuration += isNaN(diff) ? 0 : diff;
            totalDurationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);

            ++totalOccurred;

            allPbxes.forEach(pBx => {
                const { id, type } = pBx;
                if (!pbxes[id]) {
                    pbxes[id] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay), [chartDataLabel.RATE]: 0, type, display: pBxLookup[id] };
                }
                pbxes[id][chartDataLabel.TOTAL_TRIALS] += 1;
            });

            trial.abc_structured_a.forEach(a => {
                if (!_antecedentHash[a]) return; // prevent old data with antecdent name from breaking
                if (!antecedentObj[a]) antecedentObj[a] = { count: 0, pProblemBxLookup: {} };
                const temp = antecedentObj[a];
                temp.count += 1;

                // if there are replacement behaviors or sub behaviors then use that otherwise use problem behavior
                let pProblemBxList = [trial.PProblemBxId];
                if (trial.abc_structured_replacement_b.length !== 0 || trial.abc_structured_sub_b.length !== 0) {
                    pProblemBxList = [...trial.abc_structured_replacement_b, trial.abc_structured_sub_b];
                }

                pProblemBxList.forEach(p => {
                    if (!temp.pProblemBxLookup[p]) temp.pProblemBxLookup[p] = 0;
                    temp.pProblemBxLookup[p] += 1;
                });
            });
        });

        const percentage = Math.round(totalObservation === 0 ? 0 : (totalDuration / totalObservation) * 100);
        const rate = totalObservation === 0 ? 0 : totalOccurred / totalObservation;
        const averageDuration = totalOccurred === 0 ? 0 : totalDuration / totalOccurred;
        const averageDurationDisplay = totalOccurred === 0 ? 0 : totalDurationDisplay / totalOccurred;

        Object.keys(pbxes).forEach(k => {
            const pbx = pbxes[k];
            pbx[chartDataLabel.RATE] = (totalObservation === 0 ? 0 : pbx[chartDataLabel.TOTAL_TRIALS] / totalObservation).toFixed(2);
        });

        // calculate percent of opportunities given each antecedent
        Object.keys(antecedentObj).forEach((k, i) => {
            const antecedentCount = antecedentObj[k].count;
            const pProblemBxLookup = antecedentObj[k].pProblemBxLookup;

            // go through the global list of all problem behavior and look up.  Whichever behavior is there
            // then we fill in data otherwise leave percent of opportunity be 0
            // calculate percent of opportunity for each behavior
            Object.keys(pBxLookup).forEach((p, j) => {
                const pProblemBxCount = pProblemBxLookup[p] || 0;
                const percentOfOpportunity = antecedentCount === 0 ? 0 : (pProblemBxCount / antecedentCount) * 100;
                // rewriting the object
                pProblemBxLookup[p] = {
                    display: pBxLookup[p],
                    count: pProblemBxCount,
                    percentOfOpportunity: percentOfOpportunity.toFixed(2)
                }
            });

            antecedentObj[k] = {
                display: _antecedentHash[k],
                count: antecedentCount,
                pProblemBxLookup
            };
        });

        const timeUnitFirstChar = timeUnit.substring(0, 1);

        // let pProblemBxId = "";
        // if (dataSheet.PProblemBxes.length !== 0) {
        //     if (typeof dataSheet.PProblemBxes[0] === 'string') {
        //         pProblemBxId = dataSheet.PProblemBxes[0];
        //     }
        //     else {
        //         pProblemBxId = dataSheet.PProblemBxes[0].id;
        //     }
        // }

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_DURATION_DISPLAY]: getTimeFormat(totalDurationDisplay),
            [chartDataLabel.RATE]: rate.toFixed(2),
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.AVERAGE_DURATION_TIME]: averageDuration,
            [chartDataLabel.AVERAGE_DURATION_DISPLAY]: `${getTimeFormat(averageDurationDisplay)} [${averageDuration.toFixed(2)} ${timeUnitFirstChar}]`,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.ANTECEDENTS]: antecedentObj,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PPROBLEMBX_ID]: pProblemBxId,
            [chartDataLabel.PROBLEM_BEHAVIORS]: pbxes,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculateABCStructuredNew = (dataSheet, dataSheetIndex, timeUnit, pBxLookup, antecedentsLookup, pProblemBxId, _trials, isRbx = false, rbxDs = {}, isBaseline = false) => {
        const trials = getTrialsWithoutDuplicate(_trials);
        const _antecedentHash = antecedentsLookup || antecedentHash;
        const observationDs = isRbx ? rbxDs : dataSheet;
        const { totalObservation, totalObservationDisplay } = getTotalObservation(observationDs, timeUnit);

        const antecedentObj = {}
        let totalDuration = 0;
        let totalDurationDisplay = 0;
        let totalOccurred = 0;

        let pbxes = {}; // because we're separating pbx and its replacement behaviors we will create a lookup and go through trials to sort it out

        // Reason for replacing the code block above with the line below: utils.js is already looping through dataSheet.PProblemBxes, if we loop again in here, it will be nested loop and is looping through the same thing twice
        pbxes[pProblemBxId] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(0), [chartDataLabel.RATE]: 0, type: 'behavior', display: pBxLookup[pProblemBxId] };

        trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            const isValid = (isRbx && trial.PPBxesGoalsLessonId === rbxDs.PPBxesGoalsLessonId) || (!isRbx && !trial.PPBxesGoalsLessonId);
            if (!isValid) return; // if the trial belongs to an rbx, then it should have rbxId. if the trial belongs to the pbx, then it shouldn't have rbxId

            let allPbxes = [{ id: trial.PProblemBxId, type: 'behavior' }, ...trial.abc_structured_sub_b.map(id => {
                return { id, type: 'sub-behavior' };
            })];
            if (trial.abc_structured_replacement_b.length !== 0) {
                // if there are replacement behaviors, we only want to count those
                allPbxes = trial.abc_structured_replacement_b.map(id => {
                    return { id, type: 'replacement-behavior' };
                });
            }

            const start = Moment(trial.start_time);
            const end = Moment(trial.end_time);
            const diff = end.diff(start, timeUnit, true);
            const displayDiff = end.diff(start, 'milliseconds');
            totalDuration += isNaN(diff) ? 0 : diff;
            totalDurationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);

            ++totalOccurred;

            allPbxes.forEach(pBx => {
                const { id, type } = pBx;
                if (!pbxes[id]) {
                    pbxes[id] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay), [chartDataLabel.RATE]: 0, type, display: pBxLookup[id] };
                }
                pbxes[id][chartDataLabel.TOTAL_TRIALS] += 1;
            });

            trial.abc_structured_a.forEach(a => {
                if (!_antecedentHash[a]) return; // prevent old data with antecdent name from breaking
                if (!antecedentObj[a]) antecedentObj[a] = { count: 0, pProblemBxLookup: {} };
                const temp = antecedentObj[a];
                temp.count += 1;

                // if there are replacement behaviors or sub behaviors then use that otherwise use problem behavior
                let pProblemBxList = [trial.PProblemBxId];
                if (trial.abc_structured_replacement_b.length !== 0 || trial.abc_structured_sub_b.length !== 0) {
                    pProblemBxList = [...trial.abc_structured_replacement_b, trial.abc_structured_sub_b];
                }

                pProblemBxList.forEach(p => {
                    if (!temp.pProblemBxLookup[p]) temp.pProblemBxLookup[p] = 0;
                    temp.pProblemBxLookup[p] += 1;
                });
            });
        });

        const percentage = Math.round(totalObservation === 0 ? 0 : (totalDuration / totalObservation) * 100);
        const rate = totalObservation === 0 ? 0 : totalOccurred / totalObservation;
        const averageDuration = totalOccurred === 0 ? 0 : totalDuration / totalOccurred;
        const averageDurationDisplay = totalOccurred === 0 ? 0 : totalDurationDisplay / totalOccurred;

        Object.keys(pbxes).forEach(k => {
            const pbx = pbxes[k];
            pbx[chartDataLabel.RATE] = (totalObservation === 0 ? 0 : pbx[chartDataLabel.TOTAL_TRIALS] / totalObservation).toFixed(2);
        });

        // calculate percent of opportunities given each antecedent
        Object.keys(antecedentObj).forEach((k, i) => {
            const antecedentCount = antecedentObj[k].count;
            const pProblemBxLookup = antecedentObj[k].pProblemBxLookup;

            // go through the global list of all problem behavior and look up.  Whichever behavior is there
            // then we fill in data otherwise leave percent of opportunity be 0
            // calculate percent of opportunity for each behavior
            Object.keys(pBxLookup).forEach((p, j) => {
                const pProblemBxCount = pProblemBxLookup[p] || 0;
                const percentOfOpportunity = antecedentCount === 0 ? 0 : (pProblemBxCount / antecedentCount) * 100;
                // rewriting the object
                pProblemBxLookup[p] = {
                    display: pBxLookup[p],
                    count: pProblemBxCount,
                    percentOfOpportunity: percentOfOpportunity.toFixed(2)
                }
            });

            antecedentObj[k] = {
                display: _antecedentHash[k],
                count: antecedentCount,
                pProblemBxLookup
            };
        });

        const timeUnitFirstChar = timeUnit.substring(0, 1);

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_DURATION_DISPLAY]: getTimeFormat(totalDurationDisplay),
            [chartDataLabel.RATE]: rate.toFixed(2),
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.AVERAGE_DURATION_TIME]: averageDuration,
            [chartDataLabel.AVERAGE_DURATION_DISPLAY]: `${getTimeFormat(averageDurationDisplay)} [${averageDuration.toFixed(2)} ${timeUnitFirstChar}]`,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.ANTECEDENTS]: antecedentObj,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PPROBLEMBX_ID]: pProblemBxId,
            [chartDataLabel.PROBLEM_BEHAVIORS]: pbxes,
            [chartDataLabel.CONFLICT]: conflict,
            [chartDataLabel.TOTAL_DURATION_TIME]: totalDuration,
            [chartDataLabel.BASELINE]: isBaseline,
        };
    };

    // DEPRECATED
    const calculateDuration = (dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId) => {
        // const dsStart = Moment(dataSheet.start_time);
        // const dsEnd = Moment(dataSheet.end_time);
        // let totalObservation = dsEnd.diff(dsStart, timeUnit, true);
        // if (isNaN(totalObservation)) totalObservation = 0;
        // let totalObservationDisplay = dsEnd.diff(dsStart, 'milliseconds');
        // if (isNaN(totalObservationDisplay)) totalObservationDisplay = 0;
        const { totalObservation, totalObservationDisplay } = getTotalObservation(dataSheet, timeUnit);

        let totalDuration = 0;
        let totalDurationDisplay = 0;
        let totalOccurred = 0;

        const trials = dataSheet.Trials.filter(trial => pBxLookup[trial.PProblemBxId]);

        trials.forEach((trial, j) => {
            // if (!pBxLookup[trial.PProblemBxId]) return; // because we grouped multiple PBx together, we need to filter out current PBx only

            const start = Moment(trial.start_time);
            const end = Moment(trial.end_time);
            const diff = end.diff(start, timeUnit, true);
            const displayDiff = end.diff(start, 'milliseconds');
            totalDuration += isNaN(diff) ? 0 : diff;
            totalDurationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);
            ++totalOccurred;
        });

        // find the average lapse time in between trial
        let timeBetweenLapseCount = 0;
        let totalTimeBetweenLapse = 0;
        let totalTimeBetweenLapseDisplay = 0;
        trials.sort((a, b) => new Date(b.end_time) > new Date(a.end_time) ? 1 : -1).forEach((trial, k) => {
            // skip first one
            if (k < 1) {
                return;
            }

            timeBetweenLapseCount++;
            const time1 = Moment(trials[k - 1].start_time);   // last end time
            const time2 = Moment(trials[k].end_time); // next start time
            totalTimeBetweenLapse += time1.diff(time2, timeUnit, true);
            totalTimeBetweenLapseDisplay += time1.diff(time2, 'milliseconds');
        });

        const averageDuration = totalOccurred === 0 ? 0 : totalDuration / totalOccurred;
        const averageDurationDisplay = totalOccurred === 0 ? 0 : totalDurationDisplay / totalOccurred;
        const percentage = Math.round(totalObservation === 0 ? 0 : (totalDuration / totalObservation) * 100);

        const averageLapsed = timeBetweenLapseCount === 0 ? 0 : totalTimeBetweenLapse / timeBetweenLapseCount;
        const averageLapsedDisplay = timeBetweenLapseCount === 0 ? 0 : totalTimeBetweenLapseDisplay / timeBetweenLapseCount;

        const timeUnitFirstChar = timeUnit.substring(0, 1);

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_DURATION_TIME]: totalDuration,
            [chartDataLabel.TOTAL_DURATION_DISPLAY]: getTimeFormat(totalDurationDisplay),
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.AVERAGE_DURATION_TIME]: averageDuration,
            [chartDataLabel.AVERAGE_DURATION_DISPLAY]: `${getTimeFormat(averageDurationDisplay)} [${averageDuration.toFixed(2)} ${timeUnitFirstChar}]`,

            [chartDataLabel.TOTAL_LAPSED]: timeBetweenLapseCount,
            [chartDataLabel.TOTAL_TIME_LAPSED_DISPLAY]: getTimeFormat(totalTimeBetweenLapseDisplay),

            [chartDataLabel.AVERAGE_LAPSED]: averageLapsed,
            [chartDataLabel.AVERAGE_LAPSED_DISPLAY]: `${getTimeFormat(averageLapsedDisplay)} [${averageLapsed.toFixed(2)} ${timeUnitFirstChar}]`,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.TOTAL_ESPISODES]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculateDurationNew = (dataSheet, dataSheetIndex, timeUnit, pProblemBxId, trials, isRbx = false, rbxDs = {}, isBaseline = false) => {
        const observationDs = isRbx ? rbxDs : dataSheet;
        const { totalObservation, totalObservationDisplay } = getTotalObservation(observationDs, timeUnit);

        let totalDuration = 0;
        let totalDurationDisplay = 0;
        let totalOccurred = 0;

        trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            const isValid = (isRbx && trial.PPBxesGoalsLessonId === rbxDs.PPBxesGoalsLessonId) || (!isRbx && !trial.PPBxesGoalsLessonId);
            if (!isValid) return; // if the trial belongs to an rbx, then it should have rbxId. if the trial belongs to the pbx, then it shouldn't have rbxId

            const start = Moment(trial.start_time);
            const end = Moment(trial.end_time);
            const diff = end.diff(start, timeUnit, true);
            const displayDiff = end.diff(start, 'milliseconds');
            totalDuration += isNaN(diff) ? 0 : diff;
            totalDurationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);
            ++totalOccurred;
        });

        // find the average lapse time in between trial
        let timeBetweenLapseCount = 0;
        let totalTimeBetweenLapse = 0;
        let totalTimeBetweenLapseDisplay = 0;
        trials.sort((a, b) => new Date(b.end_time) > new Date(a.end_time) ? 1 : -1).forEach((trial, k) => {
            // skip first one
            if (k < 1) {
                return;
            }

            timeBetweenLapseCount++;
            const time1 = Moment(trials[k - 1].start_time);   // last end time
            const time2 = Moment(trials[k].end_time); // next start time
            totalTimeBetweenLapse += time1.diff(time2, timeUnit, true);
            totalTimeBetweenLapseDisplay += time1.diff(time2, 'milliseconds');
        });

        const averageDuration = totalOccurred === 0 ? 0 : totalDuration / totalOccurred;
        const averageDurationDisplay = totalOccurred === 0 ? 0 : totalDurationDisplay / totalOccurred;
        const percentage = Math.round(totalObservation === 0 ? 0 : (totalDuration / totalObservation) * 100);

        const averageLapsed = timeBetweenLapseCount === 0 ? 0 : totalTimeBetweenLapse / timeBetweenLapseCount;
        const averageLapsedDisplay = timeBetweenLapseCount === 0 ? 0 : totalTimeBetweenLapseDisplay / timeBetweenLapseCount;

        const timeUnitFirstChar = timeUnit.substring(0, 1);

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_DURATION_TIME]: totalDuration,
            [chartDataLabel.TOTAL_DURATION_DISPLAY]: getTimeFormat(totalDurationDisplay),
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.AVERAGE_DURATION_TIME]: averageDuration,
            [chartDataLabel.AVERAGE_DURATION_DISPLAY]: `${getTimeFormat(averageDurationDisplay)} [${averageDuration.toFixed(2)} ${timeUnitFirstChar}]`,

            [chartDataLabel.TOTAL_LAPSED]: timeBetweenLapseCount,
            [chartDataLabel.TOTAL_TIME_LAPSED_DISPLAY]: getTimeFormat(totalTimeBetweenLapseDisplay),

            [chartDataLabel.AVERAGE_LAPSED]: averageLapsed,
            [chartDataLabel.AVERAGE_LAPSED_DISPLAY]: `${getTimeFormat(averageLapsedDisplay)} [${averageLapsed.toFixed(2)} ${timeUnitFirstChar}]`,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.TOTAL_ESPISODES]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict,
            [chartDataLabel.BASELINE]: isBaseline,
        };
    };

    // DEPRECATED
    const calculateEventRecording = (dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId) => {
        // const dsStart = Moment(dataSheet.start_time);
        // const dsEnd = Moment(dataSheet.end_time);        
        // let totalObservation = dsEnd.diff(dsStart, timeUnit, true);
        // if (isNaN(totalObservation)) totalObservation = 0;
        // let totalObservationDisplay = dsEnd.diff(dsStart, 'milliseconds');
        // if (isNaN(totalObservationDisplay)) totalObservationDisplay = 0;
        const { totalObservation, totalObservationDisplay } = getTotalObservation(dataSheet, timeUnit);

        let totalOccurred = 0;

        dataSheet.Trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            ++totalOccurred;
        });

        const rate = totalObservation === 0 ? 0 : totalOccurred / totalObservation;

        // let pProblemBxId = "";
        // if (dataSheet.PProblemBxes.length !== 0) {
        //     if (typeof dataSheet.PProblemBxes[0] === 'string') {
        //         pProblemBxId = dataSheet.PProblemBxes[0];
        //     }
        //     else {
        //         pProblemBxId = dataSheet.PProblemBxes[0].id;
        //     }
        // }

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_OCCURRED]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.RATE]: rate.toFixed(2),
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PPROBLEMBX_ID]: pProblemBxId,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculateEventRecordingNew = (dataSheet, dataSheetIndex, timeUnit, pProblemBxId, _trials, isRbx = false, rbxDs = {}, isBaseline = false) => {
        const trials = getTrialsWithoutDuplicate(_trials);
        const observationDs = isRbx ? rbxDs : dataSheet;
        const { totalObservation, totalObservationDisplay } = getTotalObservation(observationDs, timeUnit);
        let totalOccurred = 0;
        trials.forEach(trial => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            const isValid = (isRbx && trial.PPBxesGoalsLessonId === rbxDs.PPBxesGoalsLessonId) || (!isRbx && !trial.PPBxesGoalsLessonId);
            if (!isValid) return; // if the trial belongs to an rbx, then it should have rbxId. if the trial belongs to the pbx, then it shouldn't have rbxId

            ++totalOccurred;
        });
        const rate = totalObservation === 0 ? 0 : totalOccurred / totalObservation;

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_OCCURRED]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.RATE]: rate.toFixed(2),
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PPROBLEMBX_ID]: pProblemBxId,
            [chartDataLabel.CONFLICT]: conflict,
            [chartDataLabel.BASELINE]: isBaseline,
        };
    };

    // DEPRECATED
    const calculateEventRecordingFrequency = (dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId) => {
        // const dsStart = Moment(dataSheet.start_time);
        // const dsEnd = Moment(dataSheet.end_time);        
        // let totalObservation = dsEnd.diff(dsStart, timeUnit, true);
        // if (isNaN(totalObservation)) totalObservation = 0;
        // let totalObservationDisplay = dsEnd.diff(dsStart, 'milliseconds');
        // if (isNaN(totalObservationDisplay)) totalObservationDisplay = 0;
        const { totalObservation, totalObservationDisplay } = getTotalObservation(dataSheet, timeUnit);

        let totalOccurred = 0;

        dataSheet.Trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            ++totalOccurred;
        });

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_OCCURRED]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict
        };
    };

    const calculateEventRecordingFrequencyNew = (dataSheet, dataSheetIndex, timeUnit, pProblemBxId, _trials, isRbx, rbxDs, isBaseline = false) => {
        const trials = getTrialsWithoutDuplicate(_trials);
        const observationDs = isRbx ? rbxDs : dataSheet;
        const { totalObservation, totalObservationDisplay } = getTotalObservation(observationDs, timeUnit);
        
        let totalOccurred = 0;
        trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            const isValid = (isRbx && trial.PPBxesGoalsLessonId === rbxDs.PPBxesGoalsLessonId) || (!isRbx && !trial.PPBxesGoalsLessonId);
            if (!isValid) return; // if the trial belongs to an rbx, then it should have rbxId. if the trial belongs to the pbx, then it shouldn't have rbxId

            ++totalOccurred;
        });

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_OCCURRED]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation,
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict,
            [chartDataLabel.BASELINE]: isBaseline,
        };
    };

    // DEPRECATED
    const calculateMomentaryTimeSampling = (dataSheet, dataSheetIndex, pBxLookup, pProblemBxId) => {
        let totalOccurred = 0;
        let totalYes = 0;

        dataSheet.Trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only

            if (trial.problem_bx_occurred !== null) ++totalOccurred;
            if (trial.problem_bx_occurred !== null && trial.problem_bx_occurred === true) ++totalYes;
        });

        const percentage = Math.round(totalOccurred === 0 ? 0 : (totalYes / totalOccurred) * 100);

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_YES]: totalYes,
            [chartDataLabel.TOTAL_INTERVALS]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculateMomentaryTimeSamplingNew = (dataSheet, dataSheetIndex, pProblemBxId, _trials, isRbx = false, rbxDs = {}, isBaseline = false) => {
        const trials = getTrialsWithoutDuplicate(_trials);
        let totalOccurred = 0;
        let totalYes = 0;

        trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            const isValid = (isRbx && trial.PPBxesGoalsLessonId === rbxDs.PPBxesGoalsLessonId) || (!isRbx && !trial.PPBxesGoalsLessonId);
            if (!isValid) return; // if the trial belongs to an rbx, then it should have rbxId. if the trial belongs to the pbx, then it shouldn't have rbxId

            if (trial.problem_bx_occurred !== null) ++totalOccurred;
            if (trial.problem_bx_occurred !== null && trial.problem_bx_occurred === true) ++totalYes;
        });

        const percentage = Math.round(totalOccurred === 0 ? 0 : (totalYes / totalOccurred) * 100);

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_YES]: totalYes,
            [chartDataLabel.TOTAL_INTERVALS]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict,
            [chartDataLabel.BASELINE]: isBaseline,
        };
    };

    // DEPRECATED
    const calculatePartialIntervalRecordingABCStructured = (dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId) => {
        let totalOccurred = 0;
        let totalYes = 0;
        let totalObservation = 0;
        let totalObservationDisplay = 0;

        let pbxes = {}; // because we're separating pbx and its replacement behaviors we will create a lookup and go through trials to sort it out

        dataSheet.Trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only

            let allPbxes = [{ id: trial.PProblemBxId, type: 'behavior' }, ...trial.abc_structured_sub_b.map(id => {
                return { id, type: 'sub-behavior' };
            })];
            if (Array.isArray(trial.abc_structured_replacement_b) && trial.abc_structured_replacement_b.length !== 0) {
                // if there are replacement behaviors, we only want to count those
                allPbxes = trial.abc_structured_replacement_b.map(id => {
                    return { id, type: 'replacement-behavior' };
                });
            }

            allPbxes.forEach(pBx => {
                const { id, type } = pBx;
                if (!pbxes[id]) {
                    pbxes[id] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_YES]: 0, [chartDataLabel.PERCENTAGE]: 0, type, display: pBxLookup[id] };
                }
            });

            const hasReplacementBehavior = trial.abc_structured_replacement_b.length !== 0;

            if (trial.problem_bx_occurred !== null) {
                ++totalOccurred;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_TRIALS] += 1;
                });
            }
            if (trial.problem_bx_occurred !== null && trial.problem_bx_occurred === true) {
                ++totalYes;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_YES] += 1;
                });
            }

            const start = Moment(trial.start_time);
            const end = Moment(trial.end_time);
            const diff = end.diff(start, timeUnit, true);
            const displayDiff = end.diff(start, 'milliseconds');
            totalObservation += isNaN(diff) ? 0 : diff;
            totalObservationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);
        });

        const percentOfIntervals = Math.round(totalOccurred === 0 ? 0 : (totalYes / totalOccurred) * 100);

        const averageDuration = totalOccurred === 0 ? 0 : totalObservation / totalOccurred;
        const averageDurationDisplay = totalOccurred === 0 ? 0 : totalObservationDisplay / totalOccurred;

        let totalBehaviorTrials = 0;
        Object.keys(pbxes).forEach(id => {
            const pbx = pbxes[id];
            if (pbx.type === 'behavior') {
                totalBehaviorTrials = pbx[chartDataLabel.TOTAL_TRIALS];
            }
        });

        Object.keys(pbxes).forEach(id => {
            const pbx = pbxes[id];
            if (pbx.type === 'sub-behavior') {
                pbx[chartDataLabel.TOTAL_TRIALS] = totalBehaviorTrials; // need to set sub-behavior to use the behavior total trials
            }

            const demoniator = pbx[chartDataLabel.TOTAL_TRIALS];
            const numerator = pbx[chartDataLabel.TOTAL_YES];
            pbx[chartDataLabel.PERCENTAGE] = Math.round(demoniator === 0 ? 0 : (numerator / demoniator) * 100);
        });

        const timeUnitFirstChar = timeUnit.substring(0, 1);

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_YES]: totalYes,
            [chartDataLabel.TOTAL_INTERVALS]: totalOccurred,
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation.toFixed(2),
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.PERCENTAGE]: percentOfIntervals,
            [chartDataLabel.AVERAGE_DURATION_TIME]: averageDuration.toFixed(2),
            [chartDataLabel.AVERAGE_DURATION_DISPLAY]: `${getTimeFormat(averageDurationDisplay)} [${averageDuration.toFixed(2)} ${timeUnitFirstChar}]`,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PROBLEM_BEHAVIORS]: pbxes,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculatePartialIntervalRecordingABCStructuredNew = (dataSheet, dataSheetIndex, timeUnit, pBxLookup, pProblemBxId, _trials, isRbx = false, rbxDs = {}, isBaseline = false) => {
        const trials = getTrialsWithoutDuplicate(_trials);
        let totalOccurred = 0;
        let totalYes = 0;
        let totalObservation = 0;
        let totalObservationDisplay = 0;

        let pbxes = {}; // because we're separating pbx and its replacement behaviors we will create a lookup and go through trials to sort it out

        trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            const isValid = (isRbx && trial.PPBxesGoalsLessonId === rbxDs.PPBxesGoalsLessonId) || (!isRbx && !trial.PPBxesGoalsLessonId);
            if (!isValid) return; // if the trial belongs to an rbx, then it should have rbxId. if the trial belongs to the pbx, then it shouldn't have rbxId

            let allPbxes = [{ id: trial.PProblemBxId, type: 'behavior' }, ...trial.abc_structured_sub_b.map(id => {
                return { id, type: 'sub-behavior' };
            })];
            if (trial.abc_structured_replacement_b.length !== 0) {
                // if there are replacement behaviors, we only want to count those
                allPbxes = trial.abc_structured_replacement_b.map(id => {
                    return { id, type: 'replacement-behavior' };
                });
            }

            allPbxes.forEach(pBx => {
                const { id, type } = pBx;
                if (!pbxes[id]) {
                    pbxes[id] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_YES]: 0, [chartDataLabel.PERCENTAGE]: 0, type, display: pBxLookup[id] };
                }
            });

            const hasReplacementBehavior = trial.abc_structured_replacement_b.length !== 0;

            if (trial.problem_bx_occurred !== null) {
                ++totalOccurred;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_TRIALS] += 1;
                });
            }
            if (trial.problem_bx_occurred !== null && trial.problem_bx_occurred === true) {
                ++totalYes;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_YES] += 1;
                });
            }

            const start = Moment(trial.start_time);
            const end = Moment(trial.end_time);
            const diff = end.diff(start, timeUnit, true);
            const displayDiff = end.diff(start, 'milliseconds');
            totalObservation += isNaN(diff) ? 0 : diff;
            totalObservationDisplay += isNaN(displayDiff) ? 0 : getRoundUpTime(displayDiff);
        });

        const percentOfIntervals = Math.round(totalOccurred === 0 ? 0 : (totalYes / totalOccurred) * 100);

        const averageDuration = totalOccurred === 0 ? 0 : totalObservation / totalOccurred;
        const averageDurationDisplay = totalOccurred === 0 ? 0 : totalObservationDisplay / totalOccurred;

        let totalBehaviorTrials = 0;
        Object.keys(pbxes).forEach(id => {
            const pbx = pbxes[id];
            if (pbx.type === 'behavior') {
                totalBehaviorTrials = pbx[chartDataLabel.TOTAL_TRIALS];
            }
        });

        Object.keys(pbxes).forEach(id => {
            const pbx = pbxes[id];
            if (pbx.type === 'sub-behavior') {
                pbx[chartDataLabel.TOTAL_TRIALS] = totalBehaviorTrials; // need to set sub-behavior to use the behavior total trials
            }

            const demoniator = pbx[chartDataLabel.TOTAL_TRIALS];
            const numerator = pbx[chartDataLabel.TOTAL_YES];
            pbx[chartDataLabel.PERCENTAGE] = Math.round(demoniator === 0 ? 0 : (numerator / demoniator) * 100);
        });

        const timeUnitFirstChar = timeUnit.substring(0, 1);

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_YES]: totalYes,
            [chartDataLabel.TOTAL_INTERVALS]: totalOccurred,
            [chartDataLabel.TOTAL_OBSERVATION_TIME]: totalObservation.toFixed(2),
            [chartDataLabel.TOTAL_OBSERVATION_DISPLAY]: getTimeFormat(totalObservationDisplay),
            [chartDataLabel.PERCENTAGE]: percentOfIntervals,
            [chartDataLabel.AVERAGE_DURATION_TIME]: averageDuration.toFixed(2),
            [chartDataLabel.AVERAGE_DURATION_DISPLAY]: `${getTimeFormat(averageDurationDisplay)} [${averageDuration.toFixed(2)} ${timeUnitFirstChar}]`,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PROBLEM_BEHAVIORS]: pbxes,
            [chartDataLabel.CONFLICT]: conflict,
            [chartDataLabel.BASELINE]: isBaseline,
        };
    };

    // TODO: Stats refactor
    // const calculatePIRWithPhases = (dataSheet, pProblemBxId, ...etc) => {
    //     // Go through the trials within the dataSheet and filter each type (problem behavior and replacement behaviors into different buckets)

    //     const buckets = {
    //         // [pProblemBxId]: { Trials, PProblemBxesDataSheets }   // Trials and PProblemBxesDataSheets are filtered for the key (pProblemBxId)
    //         // [PPBxesGoalsLessonId]: ... include  PProblemBxLessonId
    //     }
        
    //     // Go through each bucket type
    //         const bucket = buckets['id']
    //         const dataSheet = {...dataSheet, Trials: bucket.Trials, PProblemBxesDataSheets: bucket.PProblemBxesDataSheets }
    //         // calculatePartialIntervalRecording()
    //         // add in PProblemBxLessonId
    // }

    // DEPRECATED
    const calculatePartialIntervalRecording = (dataSheet, dataSheetIndex, pBxLookup, pProblemBxId) => {
        let totalOccurred = 0;
        let totalYes = 0;
        let pbxes = {}; // because we're separating pbx and its replacement behaviors we will create a lookup and go through trials to sort it out

        dataSheet.Trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only

            let allPbxes = [{ id: trial.PProblemBxId, type: 'behavior' }, ...(trial.abc_structured_sub_b || []).map(id => {
                return { id, type: 'sub-behavior' };
            })];
            if (Array.isArray(trial.abc_structured_replacement_b) && trial.abc_structured_replacement_b.length !== 0) {
                // if there are replacement behaviors, we only want to count those
                allPbxes = trial.abc_structured_replacement_b.map(id => {
                    return { id, type: 'replacement-behavior' };
                });
            }

            allPbxes.forEach(pBx => {
                const { id, type } = pBx;
                if (!pbxes[id]) {
                    pbxes[id] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_YES]: 0, [chartDataLabel.PERCENTAGE]: 0, type, display: pBxLookup[id] };
                }
            });

            // const hasReplacementBehavior = trial.abc_structured_replacement_b.length !== 0;

            if (trial.problem_bx_occurred !== null) {
                ++totalOccurred;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_TRIALS] += 1;
                });
            }

            if (trial.problem_bx_occurred !== null && trial.problem_bx_occurred === true) {
                ++totalYes;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_YES] += 1;
                });
            }
        });

        const percentage = Math.round(totalOccurred === 0 ? 0 : (totalYes / totalOccurred) * 100);

        Object.keys(pbxes).forEach(id => {
            const pbx = pbxes[id];
            const demoniator = pbx[chartDataLabel.TOTAL_TRIALS];
            const numerator = pbx[chartDataLabel.TOTAL_YES];
            pbx[chartDataLabel.PERCENTAGE] = Math.round(demoniator === 0 ? 0 : (numerator / demoniator) * 100);
        });

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_YES]: totalYes,
            [chartDataLabel.TOTAL_INTERVALS]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PROBLEM_BEHAVIORS]: pbxes,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculatePartialIntervalRecordingNew = (dataSheet, dataSheetIndex, pBxLookup, pProblemBxId, _trials, isRbx = false, rbxDs = {}, isBaseline = false) => {
        const trials = getTrialsWithoutDuplicate(_trials);
        let totalOccurred = 0;
        let totalYes = 0;
        let pbxes = {}; // because we're separating pbx and its replacement behaviors we will create a lookup and go through trials to sort it out

        trials.forEach((trial, j) => {
            if (trial.PProblemBxId !== pProblemBxId) return; // because we grouped multiple PBx together, we need to filter out current PBx only
            const isValid = (isRbx && trial.PPBxesGoalsLessonId === rbxDs.PPBxesGoalsLessonId) || (!isRbx && !trial.PPBxesGoalsLessonId);
            if (!isValid) return; // if the trial belongs to an rbx, then it should have rbxId. if the trial belongs to the pbx, then it shouldn't have rbxId

            let allPbxes = [{ id: trial.PProblemBxId, type: 'behavior' }, ...(trial.abc_structured_sub_b || []).map(id => {
                return { id, type: 'sub-behavior' };
            })];
            if (Array.isArray(trial.abc_structured_replacement_b) && trial.abc_structured_replacement_b.length !== 0) {
                // if there are replacement behaviors, we only want to count those
                allPbxes = trial.abc_structured_replacement_b.map(id => {
                    return { id, type: 'replacement-behavior' };
                });
            }

            allPbxes.forEach(pBx => {
                const { id, type } = pBx;
                if (!pbxes[id]) {
                    pbxes[id] = { [chartDataLabel.TOTAL_TRIALS]: 0, [chartDataLabel.TOTAL_YES]: 0, [chartDataLabel.PERCENTAGE]: 0, type, display: pBxLookup[id] };
                }
            });

            // const hasReplacementBehavior = trial.abc_structured_replacement_b.length !== 0;

            if (trial.problem_bx_occurred !== null) {
                ++totalOccurred;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_TRIALS] += 1;
                });
            }

            if (trial.problem_bx_occurred !== null && trial.problem_bx_occurred === true) {
                ++totalYes;
                allPbxes.forEach(pBx => {
                    const { id, type } = pBx;
                    pbxes[id][chartDataLabel.TOTAL_YES] += 1;
                });
            }
        });

        const percentage = Math.round(totalOccurred === 0 ? 0 : (totalYes / totalOccurred) * 100);

        Object.keys(pbxes).forEach(id => {
            const pbx = pbxes[id];
            const demoniator = pbx[chartDataLabel.TOTAL_TRIALS];
            const numerator = pbx[chartDataLabel.TOTAL_YES];
            pbx[chartDataLabel.PERCENTAGE] = Math.round(demoniator === 0 ? 0 : (numerator / demoniator) * 100);
        });

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TOTAL_YES]: totalYes,
            [chartDataLabel.TOTAL_INTERVALS]: totalOccurred,
            [chartDataLabel.TOTAL_TRIALS]: totalOccurred,
            [chartDataLabel.PERCENTAGE]: percentage,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.PROBLEM_BEHAVIORS]: pbxes,
            [chartDataLabel.CONFLICT]: conflict,
            [chartDataLabel.BASELINE]: isBaseline,
        };
    };

    const calculateOpportunity = (dataSheet, dataSheetIndex, pProblemBxId, trials, rbxId, rbxName) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;
        trials.forEach((trial, i) => {
            if (trial.PProblemBxId !== pProblemBxId || trial.PPBxesGoalsLessonId !== rbxId) return;
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            if (!targets[rbxId]) targets[rbxId] = { display: rbxName, totalCorrectTrials: 0, totalTrials: 0 };
            targets[rbxId].totalCorrectTrials += trial.c === true ? 1 : 0;
            targets[rbxId].totalTrials += 1;
        });

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculateTrialByTrial = (dataSheet, dataSheetIndex, pProblemBxId, trials, rbxId, rbxName) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;
        trials.forEach((trial, i) => {
            if (trial.PProblemBxId !== pProblemBxId || trial.PPBxesGoalsLessonId !== rbxId) return;
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            if (!targets[rbxId]) {
                targets[rbxId] = { display: rbxName, totalCorrectTrials: 0, totalTrials: 0 };
            }
            targets[rbxId].totalCorrectTrials += trial.c === true ? 1 : 0;
            targets[rbxId].totalTrials += 1;
        });

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }

        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]: conflict,
        };
    };

    const calculateFirstTrial = (dataSheet, dataSheetIndex, pProblemBxId, trials, rbxId, rbxName) => {
        const targets = {};    // contains all targets data and use id to lookup
        let totalTrials = 0;

        trials.forEach((trial, i) => {
            if (trial.PProblemBxId !== pProblemBxId || trial.PPBxesGoalsLessonId !== rbxId) return;
            // no selection for the trial so we ignore it
            if (!hasADxTrial(trial)) return;

            ++totalTrials;
            if (!targets[rbxId]) {
                targets[rbxId] = { trialQuantity: 0, trialScore: '', beginPrompt: trial.ftc_p_begin, endPrompt: trial.ftc_p_end, totalCorrectTrials: 0, totalTrials: 0 };
            }
            const target = targets[rbxId];
            const trialScoreAndDisplay = getTrialScoreAndSelection(trial);
            target.trialQuantity = trialScoreAndDisplay.quantity;
            target.trialScore = trialScoreAndDisplay.score;
            target.name = rbxName;
            const correctTrial = trial.c === true;
            target.totalCorrectTrials += correctTrial ? 1 : 0;
            target.totalTrials += 1;
        });

        let sequence = 0;
        let conflict = false;
        if (dataSheet.PProblemBxesDataSheets) {
            for (let i = 0; i < dataSheet.PProblemBxesDataSheets.length; i++) {
                const item = dataSheet.PProblemBxesDataSheets[i];
                if (item.PProblemBxId === pProblemBxId) {
                    sequence = `${item.sequence}${dataSheet.manual ? 'M' : ''}${(item.conflict ? 'C' : '')}`;
                    // sequence = item.no_conflict_sequence + (dataSheet.manual ? 'M' : '');
                    conflict = conflict || item.conflict;
                }
            }
        }
        return {
            [chartDataLabel.ID]: dataSheet.id,
            [chartDataLabel.DATASHEET_INDEX]: dataSheetIndex,
            [chartDataLabel.SESSION]: sequence,
            [chartDataLabel.DATE]: Moment(dataSheet.client_session_start_time),
            [chartDataLabel.INITIALS]: dataSheet.initials,
            [chartDataLabel.TARGETS]: targets,
            [chartDataLabel.TOTAL_TRIALS]: totalTrials,
            [chartDataLabel.NOTES]: dataSheet.notes,
            [chartDataLabel.CONFLICT]:conflict,
        };
    };

    return {
        calculateABCStructured,
        calculateDuration,
        calculateEventRecording,
        calculateEventRecordingFrequency,
        calculateMomentaryTimeSampling,
        calculatePartialIntervalRecordingABCStructured,
        calculatePartialIntervalRecording,

        calculateEventRecordingNew,
        calculateABCStructuredNew,
        calculateDurationNew,
        calculatePartialIntervalRecordingNew,
        calculatePartialIntervalRecordingABCStructuredNew,
        calculateMomentaryTimeSamplingNew,
        calculateFirstTrial,
        calculateOpportunity,
        calculateTrialByTrial,
        calculateEventRecordingFrequencyNew,
    };
})();
