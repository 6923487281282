import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';


const propTypes = {
    ms: PropTypes.number,
    className: PropTypes.string
};

export const getRoundUpTime = (ms) => {
    const mod = ms % 1000;
    if (mod >= 500) {
        ms = ms + (1000 - mod)
    }
    else {
        ms = ms - mod;
    }

    return ms;
}

export const getTimeFormat = (ms) => {
    ms = getRoundUpTime(ms);
    const date = Moment().startOf("day").add(ms, 'milliseconds');    
    // let timeFormat = date.hours() > 0 ? 'HH:mm:ss' : 'mm:ss';
    return date.format('HH:mm:ss');
}

class Timer extends React.Component {
    render() {
        const className = this.props.className || 'timer';

        return (
            <span className={className}>{getTimeFormat(this.props.ms)}</span>
        );
    }
}

Timer.propTypes = propTypes;


export default Timer;
