'use strict';

const { get } = require("lodash");

const API_VERSION = 2;
const API_VALID_VERSIONS = [1, 2];

const MAJOR = 3;    // Milestones
const MINOR = 6;    // Features, Enhancements
const PATCHES = 6;  // Hotfixes, Fast Track

const APP_VERSION = `${MAJOR}.${MINOR}.${PATCHES}`;
const VIEWS = {
    BT: 'BT',
    ADMIN: 'ADMIN',
}

const defaultRefresh = { [VIEWS.BT]: true, [VIEWS.ADMIN]: true };

const refreshByVersion = {   
    '3.5.0': { [VIEWS.BT]: true, [VIEWS.ADMIN]: true },
    '3.5.1': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.2': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.3': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.4': { [VIEWS.BT]: true, [VIEWS.ADMIN]: false },
    '3.5.5': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.6': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.7': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.8': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.9': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.10': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.5.11': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.6.0': { [VIEWS.BT]: true, [VIEWS.ADMIN]: true },
    '3.6.1': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.6.2': { [VIEWS.BT]: true, [VIEWS.ADMIN]: false },
    '3.6.3': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.6.4': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.6.5': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
    '3.6.6': { [VIEWS.BT]: false, [VIEWS.ADMIN]: false },
}

const getViewsRefreshByVersion = (clientVersion = '') => {
    const version = (clientVersion).split('.').map(item => parseInt(item)).filter(item => !isNaN(item));
    if (version.length !== 3) return defaultRefresh;

    // Check to see if clientVersion > APP_VERSION (current), if so refresh all
    // This situation will happen when client side is updated but not server side due to multiple server running at once
    const [major, minor, patches] = version;
    if (MAJOR < major || MINOR < minor || PATCHES < patches) {
        return defaultRefresh;
    }

    // If client version is the same as server then we don't need to refresh
    if (clientVersion === APP_VERSION) {
        return { [VIEWS.BT]: false, [VIEWS.ADMIN]: false };
    }

    // We need to use current APP_VERSION on the server to return where views needs to be refresh
    return get(refreshByVersion, APP_VERSION, defaultRefresh);
}

module.exports = { API_VERSION, API_VALID_VERSIONS, APP_VERSION, getViewsRefreshByVersion, VIEWS };