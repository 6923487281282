const getTrialsWithoutDuplicate = (_trials) => {
    // const _trials = [...trials].sort((a, b) => {
    //     // If they're the same then we'll look at the time they got created
    //     if (a.ds_sequence_no === b.ds_sequence_no) {
    //         return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
    //     }

    //     return a.ds_sequence_no > b.ds_sequence_no ? 1 : -1;
    // });

    // Remove duplicate based on key = PProblemBxId + abc_structured_sub_b + abc_structured_replacement_b
    // This is due to a bug that generates multiple same ds_sequence_no trials
    // We won't be to fix this bug completely due to it gets added on the client side
    let duplicateTrialKey = {};   // only use for each ds_sequence_no so we need to reset when it changes
    let duplicateTrialIdSet = new Set();
    let lastDSSequenceNo = -1;

    _trials.forEach((tr) => {
        const { id, ds_sequence_no, PProblemBxId, abc_structured_sub_b, abc_structured_replacement_b, PPBxesGoalsLessonId } = tr;
        const key = PPBxesGoalsLessonId
            ? `${PProblemBxId}-${PPBxesGoalsLessonId}`
            : `${PProblemBxId}-${JSON.stringify(abc_structured_sub_b)}-${JSON.stringify(abc_structured_replacement_b)}`;

        // If it's a different sequence then we update and reset duplicateTrialKey
        if (lastDSSequenceNo != ds_sequence_no) {
            lastDSSequenceNo = ds_sequence_no;
            duplicateTrialKey = {};
        }

        if (!duplicateTrialKey[key]) {
            duplicateTrialKey[key] = 0;
        }

        duplicateTrialKey[key] += 1;
        // Found a duplicate key so we'll save the id
        if (duplicateTrialKey[key] > 1) {
            duplicateTrialIdSet.add(id);
        }
    });

    // Filter out the duplicate ones that we're not sure how they got created
    return _trials.filter(tr => {
        return !duplicateTrialIdSet.has(tr.id);
    });
};

const getBehaviorName = (isRbx, data) => {
    if (!isRbx || !data.type) return data.name;
    return `${data.name} (Function: ${data.type})`;
};

module.exports = {
    getTrialsWithoutDuplicate,
    getBehaviorName,
};