const DATA_SHEET_TYPES = {
    //ABx
    DTT: 'DTTDs',
    ECHOIC: 'EchoicDs',
    FIRST_TRIAL: 'FirstTrialCorrectDs',
    TRIAL_BY_TRIAL: 'TrialByTrialDs',
    OPPORTUNITY: 'OpportunityDs',
    GENERALIZATION: 'GeneralizationDs',
    LATENCY: 'LatencyDs',
    MANDING: 'MandingDs',
    DURATION: 'DurationDs',
    MULTIPLE_TARGETS: 'MultipleTargetDs',
    COMPETENCY_CHECKLIST: 'CompetencyChecklistDs',
    TASK_ANALYSIS: 'TaskAnalysisDs',
    TASK_ANALYSIS_WITH_SUB_TARGETS: 'TaskAnalysisWithSubtargetsDs',

    //PBx
    EVENT_RECORDING_RATE: 'EventRecordingDs',
    EVENT_RECORDING_FREQUENCY: 'EventRecordingFrequencyDs',
    EVENT_RECORDING_ABC_NARRATIVE: 'EventRecordingAbcNarrativeDs',
    EVENT_RECORDING_ABC_STRUCTURED: 'AbcStructuredDs',
    PARTIAL_INTERVAL_RECORDING: 'PartialIntervalRecordingDs',
    PARTIAL_INTERVAL_RECORDING_ABC_NARRATIVE: 'PartialIntervalRecordingAbcNarrativeDs',
    PARTIAL_INTERVAL_RECORDING_ABC_STRUCTURED: 'PartialIntervalRecordingAbcStructuredDs',
    PBX_DURATION: 'DurationPbxDs',
    MOMENTARY_TIME_SAMPLING: 'MomentaryTimeSamplingDs',
}

const PBX_DATA_SHEET_TYPES = [
    DATA_SHEET_TYPES.EVENT_RECORDING_RATE,
    DATA_SHEET_TYPES.EVENT_RECORDING_FREQUENCY,
    DATA_SHEET_TYPES.EVENT_RECORDING_ABC_NARRATIVE,
    DATA_SHEET_TYPES.EVENT_RECORDING_ABC_STRUCTURED,
    DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING,
    DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING_ABC_NARRATIVE,
    DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING_ABC_STRUCTURED,
    DATA_SHEET_TYPES.PBX_DURATION,
    DATA_SHEET_TYPES.MOMENTARY_TIME_SAMPLING,
];

const DATA_SHEET_TYPES_SETTINGS = {
    //ABx
    [DATA_SHEET_TYPES.DTT]: {
        label: 'Discrete Trial Training (DTT)'
    },
    [DATA_SHEET_TYPES.ECHOIC]: {
        label: 'Echoic'
    },
    [DATA_SHEET_TYPES.FIRST_TRIAL]: {
        label: 'First Trial Data'
    },
    [DATA_SHEET_TYPES.TRIAL_BY_TRIAL]: {
        label: 'Trial By Trial'
    },
    [DATA_SHEET_TYPES.OPPORTUNITY]: {
        label: 'Percentage of Opportunities'
    },
    [DATA_SHEET_TYPES.GENERALIZATION]: {
        label: 'Generalization'
    },
    [DATA_SHEET_TYPES.LATENCY]: {
        label: 'Latency'
    },
    [DATA_SHEET_TYPES.MANDING]: {
        label: 'Manding'
    },
    [DATA_SHEET_TYPES.DURATION]: {
        label: 'Duration'
    },
    [DATA_SHEET_TYPES.MULTIPLE_TARGETS]: {
        label: 'Multiple Targets'
    },
    [DATA_SHEET_TYPES.COMPETENCY_CHECKLIST]: {
        label: 'Competency Checklist'
    },
    [DATA_SHEET_TYPES.TASK_ANALYSIS]: {
        label: 'Task Analysis'
    },
    [DATA_SHEET_TYPES.TASK_ANALYSIS_WITH_SUB_TARGETS]: {
        label: 'Task Analysis With Sub-Steps'
    },

    //PBx
    [DATA_SHEET_TYPES.EVENT_RECORDING_RATE]: {
        label: 'Event Recording - Rate'
    },
    [DATA_SHEET_TYPES.EVENT_RECORDING_FREQUENCY]: {
        label: 'Event Recording - Frequency'
    },
    [DATA_SHEET_TYPES.EVENT_RECORDING_ABC_NARRATIVE]: {
        label: 'Event Recording ABC Narrative'
    },
    [DATA_SHEET_TYPES.EVENT_RECORDING_ABC_STRUCTURED]: {
        label: 'Event Recording ABC Structured'
    },
    [DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING]: {
        label: 'Partial Interval Recording'
    },
    [DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING_ABC_NARRATIVE]: {
        label: 'Partial Interval Recording ABC Narrative'
    },
    [DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING_ABC_STRUCTURED]: {
        label: 'Partial Interval Recording ABC Structured'
    },
    [DATA_SHEET_TYPES.PBX_DURATION]: {
        label: 'Duration'
    },
    [DATA_SHEET_TYPES.MOMENTARY_TIME_SAMPLING]: {
        label: 'Momentary Time Sampling'
    },
}

const REDUCTION_TYPES = {
    PERCENT_OF_INTERVALS: 'Percent_Of_Intervals',
    RATE: 'Rate',
    FREQUENCY: 'Frequency',
    PERCENT_OF_TOTAL_DURATION: 'Percent_Of_Total_Duration',
    AVERAGE_DURATION: 'Average_Duration'
}

const REDUCTION_TYPES_SETTINGS = {
    [REDUCTION_TYPES.PERCENT_OF_INTERVALS]: {
        label: 'Percent of Intervals'
    },
    [REDUCTION_TYPES.RATE]: {
        label: 'Rate'
    },
    [REDUCTION_TYPES.FREQUENCY]: {
        label: 'Frequency'
    },
    [REDUCTION_TYPES.PERCENT_OF_TOTAL_DURATION]: {
        label: 'Percent of Total Duration'
    },
    [REDUCTION_TYPES.AVERAGE_DURATION]: {
        label: 'Average Duration'
    }
}

const CLOCK_TYPES = {
    NONE: 'None',
    ALL: 'All', // so we can start all timers
    OBSERVATION_CLOCK: 'Observation Clock',
    INTERVAL_TIMER: 'Interval Timer',
};

const PBX_DATA_SHEETS_WITH_TIME = {
    [DATA_SHEET_TYPES.EVENT_RECORDING_RATE]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
    [DATA_SHEET_TYPES.EVENT_RECORDING_FREQUENCY]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
    [DATA_SHEET_TYPES.EVENT_RECORDING_ABC_NARRATIVE]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
    [DATA_SHEET_TYPES.EVENT_RECORDING_ABC_STRUCTURED]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
    [DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING]: {
        type: CLOCK_TYPES.INTERVAL_TIMER,
    },
    [DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING_ABC_NARRATIVE]: {
        type: CLOCK_TYPES.INTERVAL_TIMER,
    },
    [DATA_SHEET_TYPES.PARTIAL_INTERVAL_RECORDING_ABC_STRUCTURED]: {
        type: CLOCK_TYPES.INTERVAL_TIMER,
    },
    [DATA_SHEET_TYPES.PBX_DURATION]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
    [DATA_SHEET_TYPES.MOMENTARY_TIME_SAMPLING]: {
        type: CLOCK_TYPES.INTERVAL_TIMER,
    },
};

const ABX_DATA_SHEETS_WITH_TIME = {
    [DATA_SHEET_TYPES.LATENCY]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
    [DATA_SHEET_TYPES.MANDING]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
    [DATA_SHEET_TYPES.DURATION]: {
        type: CLOCK_TYPES.OBSERVATION_CLOCK,
    },
};

module.exports = {
    DATA_SHEET_TYPES,
    PBX_DATA_SHEET_TYPES,
    DATA_SHEET_TYPES_SETTINGS,
    REDUCTION_TYPES,
    REDUCTION_TYPES_SETTINGS,
    CLOCK_TYPES,
    PBX_DATA_SHEETS_WITH_TIME,
    ABX_DATA_SHEETS_WITH_TIME,
};
